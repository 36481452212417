import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveDynamicComponent as _resolveDynamicComponent } from "vue"

const _hoisted_1 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_toolbar_title = _resolveComponent("v-toolbar-title")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_toolbar = _resolveComponent("v-toolbar")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_data_table = _resolveComponent("v-data-table")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_dialog = _resolveComponent("v-dialog")!
  const _component_v_snackbar = _resolveComponent("v-snackbar")!
  const _component_v_container = _resolveComponent("v-container")!

  return (_openBlock(), _createBlock(_component_v_container, null, {
    default: _withCtx(() => [
      (!_ctx.editedVehicle && !_ctx.addVehicleDialog && !_ctx.viewVehicleDialog)
        ? (_openBlock(), _createBlock(_component_v_btn, {
            key: 0,
            onClick: _ctx.openAddVehicleDialog,
            class: "float-right"
          }, {
            default: _withCtx(() => [
              _createTextVNode("Neues Fahrzeug")
            ]),
            _: 1
          }, 8, ["onClick"]))
        : _createCommentVNode("", true),
      (!_ctx.editedVehicle && !_ctx.addVehicleDialog && !_ctx.viewVehicleDialog)
        ? (_openBlock(), _createBlock(_component_v_data_table, {
            key: 1,
            headers: _ctx.vehicleHeaders,
            items: _ctx.filteredVehicles,
            class: "elevation-1 mt-12",
            search: _ctx.search,
            "items-per-page": 10
          }, {
            top: _withCtx(() => [
              _createVNode(_component_v_toolbar, { flat: "" }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_toolbar_title, null, {
                    default: _withCtx(() => [
                      _createTextVNode("Fahrzeugverwaltung")
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_spacer),
                  _createVNode(_component_v_text_field, {
                    modelValue: _ctx.search,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.search) = $event)),
                    label: "Suche",
                    class: "mx-4 mt-3",
                    variant: "underlined"
                  }, null, 8, ["modelValue"])
                ]),
                _: 1
              })
            ]),
            [`item.numberplate`]: _withCtx(({ item }) => [
              _createElementVNode("span", {
                size: "small",
                class: "me-2",
                onClick: ($event: any) => (_ctx.openViewVehicleDialog(item)),
                style: {"cursor":"pointer"}
              }, _toDisplayString(item.numberplate), 9, _hoisted_1)
            ]),
            [`item.actions`]: _withCtx(({ item }) => [
              _createVNode(_component_v_icon, {
                size: "small",
                class: "me-2",
                onClick: ($event: any) => (_ctx.openEditVehicleDialog(item))
              }, {
                default: _withCtx(() => [
                  _createTextVNode("mdi-pencil")
                ]),
                _: 2
              }, 1032, ["onClick"]),
              _createVNode(_component_v_icon, {
                size: "small",
                class: "me-2",
                onClick: ($event: any) => (_ctx.openDeleteVehicleDialog(item))
              }, {
                default: _withCtx(() => [
                  _createTextVNode("mdi-delete")
                ]),
                _: 2
              }, 1032, ["onClick"])
            ]),
            _: 2
          }, 1032, ["headers", "items", "search"]))
        : _createCommentVNode("", true),
      (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.addVehicleComponent), {
        modelValue: _ctx.addVehicleDialog,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.addVehicleDialog) = $event)),
        addVehicleDialog: _ctx.addVehicleDialog,
        onVehicleAdded: _ctx.onVehicleAdded
      }, null, 40, ["modelValue", "addVehicleDialog", "onVehicleAdded"])),
      (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.editVehicleComponent), {
        modelValue: _ctx.editVehicleDialog,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.editVehicleDialog) = $event)),
        vehicleToEdit: _ctx.editedVehicle,
        onVehicleUpdated: _ctx.onVehicleUpdated,
        onClose: _ctx.editVehicleClose
      }, null, 40, ["modelValue", "vehicleToEdit", "onVehicleUpdated", "onClose"])),
      (_ctx.selectedVehicle)
        ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.viewVehicleComponent), {
            key: 2,
            modelValue: _ctx.viewVehicleDialog,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.viewVehicleDialog) = $event)),
            vehicleToView: _ctx.selectedVehicle,
            onClose: _ctx.viewVehicleClose
          }, null, 40, ["modelValue", "vehicleToView", "onClose"]))
        : _createCommentVNode("", true),
      _createVNode(_component_v_dialog, {
        modelValue: _ctx.deleteVehicleDialog,
        "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.deleteVehicleDialog) = $event)),
        "max-width": "400",
        persistent: ""
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_card, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_card_title, { class: "headline" }, {
                default: _withCtx(() => [
                  _createTextVNode("Fahrzeug löschen")
                ]),
                _: 1
              }),
              _createVNode(_component_v_card_text, null, {
                default: _withCtx(() => [
                  _createTextVNode(" Möchten Sie das Fahrzeug mit dem Kennzeichen \"" + _toDisplayString(_ctx.selectedVehicle?.numberplate) + "\" wirklich löschen? ", 1)
                ]),
                _: 1
              }),
              _createVNode(_component_v_card_actions, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_spacer),
                  _createVNode(_component_v_btn, {
                    color: "blue darken-1",
                    text: "",
                    onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.deleteVehicleDialog = false))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("Abbrechen")
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_btn, {
                    color: "blue darken-1",
                    text: "",
                    onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.deleteVehicle(_ctx.selectedVehicle)))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("Löschen")
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["modelValue"]),
      _createVNode(_component_v_snackbar, {
        modelValue: _ctx.snackbar.value,
        "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.snackbar.value) = $event)),
        color: _ctx.snackbar.color,
        timeout: 4000
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.snackbar.message), 1)
        ]),
        _: 1
      }, 8, ["modelValue", "color"])
    ]),
    _: 1
  }))
}