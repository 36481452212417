<template>
    <error-snackbar v-model:snackbar="errorSnackbar" />
    <v-container fluid class="fill-height">
      <v-row align="center" justify="center">
        <v-col cols="12" sm="6" md="3">
            <div class="logo-wrapper">
            <v-img
                src="/img/logo.png"
                max-height="150"
                contain
                class="logo"
            ></v-img>
            </div>
            <v-card class="rounded-xl" style="opacity: 0.9">
                <v-card-title prepend-icon="mdi-home" style="height: 50px">

                </v-card-title>
    
                <v-card-text class="mt-8">
                    <v-form ref="form" @submit.prevent="submit">
                        <v-text-field
                        label="Benutzername"
                        v-model="username"
                        :rules="[required]"
                        required
                        prepend-icon="mdi-account-outline"
                        ></v-text-field>
                        <v-text-field
                        label="Passwort"
                        v-model="password"
                        type="password"
                        :rules="[required]"
                        required
                        prepend-icon="mdi-lock-outline"
                        ></v-text-field>
                        <v-select
                            label="System auswählen"
                            :items="[
                                { text: 'Test System', value: 'test' },
                                { text: 'Los Santos Fire Department', value: 'fire' },
                                { text: 'Los Santos Medical Department', value: 'medical' },
                                { text: 'San Andreas Police Department', value: 'police' },
                                { text: 'San Andreas State Park', value: 'statepark' },
                                { text: 'City Administration San Andreas', value: 'casa' },
                                { text: 'Department of Justice', value: 'justice' },
                            ]"
                            item-title="text"
                            item-value="value"
                            prepend-icon="mdi-domain"
                            v-model="authority"
                        ></v-select>
                        <v-checkbox
                        label="Automatisch eingeloggt bleiben"
                        v-model="autoLogin"
                        ></v-checkbox>
                        <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn 
                                    variant="tonal" 
                                    type="submit" 
                                    class="mt-n8 flex-grow-1" 
                                    size="large"
                                    height="48"
                                    >Anmelden</v-btn>
                        </v-card-actions>
                    </v-form>
                </v-card-text>
            </v-card>
        </v-col>
      </v-row>
    </v-container>
  </template>
  
  
  <script lang="ts">
  import { defineComponent, ref } from "vue";
  import api from "@/api";
  import { useStore } from "vuex";
  import { useRouter } from "vue-router";
  import bcrypt from "bcryptjs";
  
  export default defineComponent({
    setup() {
        const store = useStore();
        const router = useRouter();
        
        const autoLogin = ref(false);
        const username = ref("");
        const password = ref("");
        const authority = ref("");
        const required = (value: string) => !!value || "This field is required.";
        const errorSnackbar = ref<any>({ visible: false, message: "" });
  
        const submit = async () => {
        // You can add form validation here if needed
            try{
                const response = await api.post("login/", `action=login&username=${encodeURIComponent(username.value)}&password=${encodeURIComponent(password.value)}&remember_me=${autoLogin.value}&authority=${encodeURIComponent(authority.value)}`, {
                    headers: {
                        "Content-Type": "application/x-www-form-urlencoded",
                    },
                });

                if (response.data && response.data.jwt) {
                    console.log(response.data.user);
                    store.dispatch("authenticate", {
                        user: response.data.user,
                        jwt: response.data.jwt,
                    });
                    // Redirect to a protected page or the main page after successful login
                    router.push({ name: "dashboard" });
                } else {
                    // Handle unsuccessful login attempts (e.g., display an error message)
                    errorSnackbar.value.message = response.data.error;
                    errorSnackbar.value.visible  = true;
                }
            }catch(error){
                errorSnackbar.value.message = error;
                errorSnackbar.value.visible  = true;
            }
        };

        const forgotPassword = () => {
            // Führen Sie die gewünschte Aktion aus, z.B. eine Benachrichtigung anzeigen oder zur Passwort-vergessen-Seite navigieren
            console.log("forgot pw");
        };

  
        return {
            username,
            password,
            authority,
            required,
            submit,
            autoLogin,
            forgotPassword,
            errorSnackbar,
        };
    },
  });
</script>
<style scoped>
    .logo-wrapper {
        position: relative;
        display: flex;
        justify-content: center;
        margin-bottom: -75px; /* Die Hälfte der Logo-Höhe */
    }

    .logo {
        position: relative;
        z-index: 1;
    }
</style>
