import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_tab = _resolveComponent("v-tab")!
  const _component_v_tabs = _resolveComponent("v-tabs")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_checkbox = _resolveComponent("v-checkbox")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_window_item = _resolveComponent("v-window-item")!
  const _component_ckeditor = _resolveComponent("ckeditor")!
  const _component_v_window = _resolveComponent("v-window")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_card = _resolveComponent("v-card")!

  return (_ctx.viewCompanyDialog)
    ? (_openBlock(), _createBlock(_component_v_card, { key: 0 }, {
        default: _withCtx(() => [
          _createVNode(_component_v_card_title, null, {
            default: _withCtx(() => [
              _createTextVNode("Firma Details")
            ]),
            _: 1
          }),
          _createVNode(_component_v_tabs, {
            modelValue: _ctx.currentTab,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.currentTab) = $event)),
            "background-color": "primary",
            dark: ""
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_tab, { value: 1 }, {
                default: _withCtx(() => [
                  _createTextVNode("Details")
                ]),
                _: 1
              }),
              _createVNode(_component_v_tab, { value: 2 }, {
                default: _withCtx(() => [
                  _createTextVNode("Beschreibung")
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["modelValue"]),
          _createVNode(_component_v_window, {
            modelValue: _ctx.currentTab,
            "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.currentTab) = $event))
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_window_item, { value: 1 }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_row, { class: "pa-4" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_col, { cols: "12" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_row, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_col, { cols: "6" }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_v_text_field, {
                                    label: "Name",
                                    modelValue: _ctx.localCompanyToView.name,
                                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.localCompanyToView.name) = $event)),
                                    readonly: ""
                                  }, null, 8, ["modelValue"])
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_v_col, { cols: "6" }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_v_text_field, {
                                    label: "Standort",
                                    modelValue: _ctx.localCompanyToView.location,
                                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.localCompanyToView.location) = $event)),
                                    readonly: ""
                                  }, null, 8, ["modelValue"])
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_v_col, { cols: "6" }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_v_text_field, {
                                    label: "Leitstellennummer",
                                    modelValue: _ctx.localCompanyToView.phonenumber,
                                    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.localCompanyToView.phonenumber) = $event)),
                                    readonly: ""
                                  }, null, 8, ["modelValue"])
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_v_col, { cols: "6" }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_v_text_field, {
                                    label: "Venture Mail",
                                    modelValue: _ctx.localCompanyToView.email,
                                    "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.localCompanyToView.email) = $event)),
                                    readonly: ""
                                  }, null, 8, ["modelValue"])
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_v_col, { cols: "6" }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_v_text_field, {
                                    label: "CEO",
                                    modelValue: _ctx.localCompanyToView.ceo,
                                    "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.localCompanyToView.ceo) = $event)),
                                    readonly: ""
                                  }, null, 8, ["modelValue"])
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_v_col, { cols: "6" }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_v_text_field, {
                                    label: "Co-CEO",
                                    modelValue: _ctx.localCompanyToView.co_ceo,
                                    "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.localCompanyToView.co_ceo) = $event)),
                                    readonly: ""
                                  }, null, 8, ["modelValue"])
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_v_col, { cols: "6" }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_v_text_field, {
                                    label: "Typ",
                                    modelValue: _ctx.localCompanyToView.type_name,
                                    "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.localCompanyToView.type_name) = $event)),
                                    readonly: ""
                                  }, null, 8, ["modelValue"])
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_v_col, { cols: "6" }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_v_text_field, {
                                    label: "Ansprechpartner & Nummer",
                                    modelValue: _ctx.localCompanyToView.contact_person,
                                    "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.localCompanyToView.contact_person) = $event)),
                                    readonly: ""
                                  }, null, 8, ["modelValue"])
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_v_col, { cols: "6" }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_v_text_field, {
                                    label: "Letzte Brandschutzbegehung",
                                    modelValue: _ctx.localCompanyToView.last_fire_protection_inspection,
                                    "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.localCompanyToView.last_fire_protection_inspection) = $event)),
                                    readonly: "",
                                    type: "date"
                                  }, null, 8, ["modelValue"])
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_v_col, { cols: "6" }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_v_text_field, {
                                    label: "Brandschutzbegehung gültig bis",
                                    modelValue: _ctx.localCompanyToView.fire_protection_inspection_valid_until,
                                    "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.localCompanyToView.fire_protection_inspection_valid_until) = $event)),
                                    readonly: "",
                                    type: "date"
                                  }, null, 8, ["modelValue"])
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_v_col, { cols: "12" }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_v_checkbox, {
                                    modelValue: _ctx.localCompanyToView.contract_available,
                                    "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.localCompanyToView.contract_available) = $event)),
                                    label: "Vertrag verfügbar",
                                    readonly: ""
                                  }, null, 8, ["modelValue"])
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_v_window_item, { value: 2 }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_col, {
                    cols: "12",
                    class: "pa-4"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ckeditor, {
                        editor: _ctx.EditorClass,
                        config: _ctx.EditorClass.defaultConfig,
                        modelValue: _ctx.localCompanyToView.description,
                        "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.localCompanyToView.description) = $event)),
                        disabled: true
                      }, null, 8, ["editor", "config", "modelValue"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["modelValue"]),
          _createVNode(_component_v_card_actions, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_spacer),
              _createVNode(_component_v_btn, {
                color: "blue darken-1",
                text: "",
                onClick: _ctx.closeView
              }, {
                default: _withCtx(() => [
                  _createTextVNode("Schließen")
                ]),
                _: 1
              }, 8, ["onClick"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }))
    : _createCommentVNode("", true)
}