<template>
  <v-container>
    <v-data-table :headers="statusHeaders" :items="statuses" class="elevation-1">
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Status verwalten</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-btn @click="openNewStatusDialog">Neuen Status</v-btn>
        </v-toolbar>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon size="small" class="me-2" @click="openEditStatusDialog(item)">mdi-pencil</v-icon>
        <v-icon size="small" class="me-2" @click="openDeleteStatusDialog(item)">mdi-delete</v-icon>
      </template>
    </v-data-table>

    <!-- New Status Dialog -->
    <v-dialog v-model="newStatusDialog" max-width="60%">
      <v-card>
        <v-card-title>Neuer Status</v-card-title>
        <v-card-text>
          <v-form ref="newStatusForm">
            <v-text-field label="Name" v-model="newStatus.name" required :rules="[requiredRule]"></v-text-field>
            <v-text-field label="Sortierung" v-model="newStatus.sort_order" type="number"></v-text-field>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="newStatusDialog = false">Abbrechen</v-btn>
          <v-btn color="blue darken-1" text @click="addNewStatus" :disabled="!isFormValid(newStatus)">Hinzufügen</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Edit Status Dialog -->
    <v-dialog v-model="editStatusDialog" max-width="60%">
      <v-card>
        <v-card-title>Status bearbeiten</v-card-title>
        <v-card-text>
          <v-form ref="editStatusForm">
            <v-text-field label="Name" v-model="editedStatus.name" required :rules="[requiredRule]"></v-text-field>
            <v-text-field label="Sortierung" v-model="editedStatus.sort_order" type="number"></v-text-field>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="editStatusDialog = false">Abbrechen</v-btn>
          <v-btn color="blue darken-1" text @click="updateStatus">Speichern</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Delete Status Dialog -->
    <v-dialog v-model="deleteStatusDialog" max-width="400" persistent>
      <v-card>
        <v-card-title class="headline">Status löschen</v-card-title>
        <v-card-text>
          Möchten Sie den Status "{{ selectedStatus?.name }}" wirklich löschen?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="deleteStatusDialog = false">Abbrechen</v-btn>
          <v-btn color="blue darken-1" text @click="deleteStatus(selectedStatus)">Löschen</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Snackbar -->
    <v-snackbar v-model="snackbar.value" :color="snackbar.color" :timeout="4000">
      {{ snackbar.message }}
    </v-snackbar>
  </v-container>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from 'vue';
import api from '@/api';
import { ReportStatus } from '@/types/Report';

export default defineComponent({
  setup() {
    const errorSnackbar = ref<any>({ visible: false, message: '' });
    const statusHeaders = [
      { title: 'Name', align: 'start', sortable: false, key: 'name' },
      { title: 'Sortierung', align: 'start', sortable: false, key: 'sort_order' },
      { title: 'Aktionen', key: 'actions', sortable: false }
    ];

    const statuses = ref<ReportStatus[]>([]);
    const newStatusDialog = ref(false);
    const editStatusDialog = ref(false);
    const deleteStatusDialog = ref(false);
    const selectedStatus = ref<ReportStatus | null>(null);

    const newStatus = ref({
      name: '',
      sort_order: 0
    });

    const editedStatus = ref<ReportStatus>({
      id: 0,
      name: '',
      sort_order: 0,
      is_deleted: false
    });

    const snackbar = ref({
      value: false,
      color: '',
      message: ''
    });

    const requiredRule = (value: string) => !!value || 'Dieses Feld ist erforderlich.';

    const fetchStatuses = async () => {
      try {
        const response = await api.get('report/?action=getStatuses');
        statuses.value = response.data.map((status: ReportStatus) => ({
          ...status,
          sort_order: status.sort_order || 0
        }));
      } catch (error) {
        errorSnackbar.value.message = error.response.data.error;
        errorSnackbar.value.visible = true;
      }
    };

    const addNewStatus = async () => {
      try {
        await api.post('report/?action=addStatus', newStatus.value);
        await fetchStatuses();
        newStatusDialog.value = false;
        newStatus.value = { name: '', sort_order: 0 }; // Reset
      } catch (error) {
        errorSnackbar.value.message = error.response.data.error;
        errorSnackbar.value.visible = true;
      }
    };

    const openEditStatusDialog = (status: ReportStatus) => {
      editedStatus.value = { ...status };
      editStatusDialog.value = true;
    };

    const updateStatus = async () => {
      try {
        await api.post('report/?action=editStatus', editedStatus.value);
        await fetchStatuses();
        editStatusDialog.value = false;
      } catch (error) {
        errorSnackbar.value.message = error.response.data.error;
        errorSnackbar.value.visible = true;
      }
    };

    const openDeleteStatusDialog = (status: ReportStatus) => {
      selectedStatus.value = status;
      deleteStatusDialog.value = true;
    };

    const deleteStatus = async (status: ReportStatus) => {
      try {
        await api.post('report/?action=deleteStatus', { id: status.id });
        await fetchStatuses();
        deleteStatusDialog.value = false;
      } catch (error) {
        errorSnackbar.value.message = error.response.data.error;
        errorSnackbar.value.visible = true;
      }
    };

    function isFormValid(form: { name: string; sort_order: number }) {
      return form.name !== '';
    }

    const openNewStatusDialog = () => {
      newStatus.value = { name: '', sort_order: 0 };
      newStatusDialog.value = true;
    };

    onMounted(async () => {
      await fetchStatuses();
    });

    return {
      statusHeaders,
      statuses,
      newStatusDialog,
      newStatus,
      addNewStatus,
      requiredRule,
      editStatusDialog,
      editedStatus,
      updateStatus,
      deleteStatusDialog,
      selectedStatus,
      deleteStatus,
      openEditStatusDialog,
      openDeleteStatusDialog,
      isFormValid,
      errorSnackbar,
      snackbar,
      openNewStatusDialog
    };
  }
});
</script>

<style scoped>
</style>
