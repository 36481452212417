<template>
	<!-- Firma Details Card -->
	<v-card v-if="viewCompanyDialog">
		<v-card-title>Firma Details</v-card-title>

		<!-- Tabs für verschiedene Ansichten -->
		<v-tabs v-model="currentTab" background-color="primary" dark>
			<v-tab :value="1">Details</v-tab>
			<v-tab :value="2">Beschreibung</v-tab>
		</v-tabs>

		<!-- Inhalt der Tabs -->
		<v-window v-model="currentTab">
			<!-- Tab 1: Firmendetails -->
			<v-window-item :value="1">
				<v-row class="pa-4">
					<v-col cols="12">
						<v-row>
							<v-col cols="6">
								<v-text-field
									label="Name"
									v-model="localCompanyToView.name"
									readonly
								></v-text-field>
							</v-col>
							<v-col cols="6">
								<v-text-field
									label="Standort"
									v-model="localCompanyToView.location"
									readonly
								></v-text-field>
							</v-col>
							<v-col cols="6">
								<v-text-field
									label="Leitstellennummer"
									v-model="localCompanyToView.phonenumber"
									readonly
								></v-text-field>
							</v-col>
							<v-col cols="6">
								<v-text-field
									label="Venture Mail"
									v-model="localCompanyToView.email"
									readonly
								></v-text-field>
							</v-col>
							<v-col cols="6">
								<v-text-field
									label="CEO"
									v-model="localCompanyToView.ceo"
									readonly
								></v-text-field>
							</v-col>
							<v-col cols="6">
								<v-text-field
									label="Co-CEO"
									v-model="localCompanyToView.co_ceo"
									readonly
								></v-text-field>
							</v-col>
							<v-col cols="6">
								<v-text-field
									label="Typ"
									v-model="localCompanyToView.type_name"
									readonly
								></v-text-field>
							</v-col>
							<v-col cols="6">
								<v-text-field
									label="Ansprechpartner & Nummer"
									v-model="localCompanyToView.contact_person"
									readonly
								></v-text-field>
							</v-col>
							<v-col cols="6">
								<v-text-field
									label="Letzte Brandschutzbegehung"
									v-model="localCompanyToView.last_fire_protection_inspection"
									readonly
									type="date"
								></v-text-field>
							</v-col>
							<v-col cols="6">
								<v-text-field
									label="Brandschutzbegehung gültig bis"
									v-model="localCompanyToView.fire_protection_inspection_valid_until"
									readonly
									type="date"
								></v-text-field>
							</v-col>
							<v-col cols="12">
								<v-checkbox
									v-model="localCompanyToView.contract_available"
									label="Vertrag verfügbar"
									readonly
								></v-checkbox>
							</v-col>
						</v-row>
					</v-col>
				</v-row>
			</v-window-item>

			<!-- Tab 2: Beschreibung -->
			<v-window-item :value="2">
				<v-col cols="12" class="pa-4">
					<ckeditor
						:editor="EditorClass"
						:config="EditorClass.defaultConfig"
						v-model="localCompanyToView.description"
						:disabled="true"
					></ckeditor>
				</v-col>
			</v-window-item>
		</v-window>

		<!-- Aktionsbutton -->
		<v-card-actions>
			<v-spacer></v-spacer>
			<v-btn
				color="blue darken-1"
				text
				@click="closeView"
			>Schließen</v-btn>
		</v-card-actions>
	</v-card>
</template>

<script lang="ts">
import { defineComponent, ref, watch } from "vue";
import { Company } from "@/types/Company";
import EditorClass from "@/ckeditor/ckeditor.js";

export default defineComponent({
	name: "ViewCompanyFile",
	props: {
		modelValue: {
			type: Boolean,
			required: true,
		},
		companyToView: {
			type: Object as () => Company,
			required: true,
		},
        viewCompanyDialog: {
			type: Boolean,
			required: true,
		},
	},
	emits: ["update:modelValue"],
	setup(props, { emit }) {
		const currentTab = ref(1);
		// Create a local copy of companyToView to avoid directly mutating the prop
		const localCompanyToView = ref<Company>({ ...props.companyToView });

		// Watch for changes in the companyToView prop and update localCompanyToView accordingly
		watch(
			() => props.companyToView,
			(newVal) => {
				localCompanyToView.value = { ...newVal };
			},
			{ immediate: true }
		);

		const closeView = () => {
			emit("update:modelValue", false);
		};

		return {
			localCompanyToView,
			EditorClass,
			currentTab,
			closeView,
		};
	},
});
</script>

<style scoped>
.float-right {
	float: right;
}
</style>
