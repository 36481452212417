import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_tab = _resolveComponent("v-tab")!
  const _component_v_tabs = _resolveComponent("v-tabs")!
  const _component_v_select = _resolveComponent("v-select")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_checkbox = _resolveComponent("v-checkbox")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_form = _resolveComponent("v-form")!
  const _component_v_window_item = _resolveComponent("v-window-item")!
  const _component_ckeditor = _resolveComponent("ckeditor")!
  const _component_v_window = _resolveComponent("v-window")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_card = _resolveComponent("v-card")!

  return (_ctx.personToEdit)
    ? (_openBlock(), _createBlock(_component_v_card, { key: 0 }, {
        default: _withCtx(() => [
          _createVNode(_component_v_card_title, null, {
            default: _withCtx(() => [
              _createTextVNode(" Person bearbeiten ")
            ]),
            _: 1
          }),
          _createVNode(_component_v_tabs, {
            modelValue: _ctx.currentTab,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.currentTab) = $event)),
            "background-color": "primary",
            dark: ""
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_tab, { value: 1 }, {
                default: _withCtx(() => [
                  _createTextVNode("Personeninfos")
                ]),
                _: 1
              }),
              _createVNode(_component_v_tab, { value: 2 }, {
                default: _withCtx(() => [
                  _createTextVNode("Zusätzliche Informationen")
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["modelValue"]),
          _createVNode(_component_v_window, {
            modelValue: _ctx.currentTab,
            "onUpdate:modelValue": _cache[27] || (_cache[27] = ($event: any) => ((_ctx.currentTab) = $event))
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_window_item, { value: 1 }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_form, {
                    ref: "form",
                    class: "pa-4"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_row, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_col, { cols: "12" }, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_row, null, {
                                default: _withCtx(() => [
                                  _createVNode(_component_v_col, { cols: "2" }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_v_select, {
                                        label: "Geschlecht",
                                        modelValue: _ctx.person.gender,
                                        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.person.gender) = $event)),
                                        items: _ctx.genderOptions,
                                        "item-value": "value",
                                        "item-title": "text",
                                        required: "",
                                        rules: [_ctx.requiredRule]
                                      }, null, 8, ["modelValue", "items", "rules"])
                                    ]),
                                    _: 1
                                  }),
                                  _createVNode(_component_v_col, { cols: "2" }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_v_text_field, {
                                        label: "Titel",
                                        modelValue: _ctx.person.title,
                                        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.person.title) = $event))
                                      }, null, 8, ["modelValue"])
                                    ]),
                                    _: 1
                                  }),
                                  _createVNode(_component_v_col, { cols: "4" }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_v_text_field, {
                                        label: "Vorname",
                                        modelValue: _ctx.person.firstname,
                                        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.person.firstname) = $event)),
                                        required: "",
                                        rules: [_ctx.requiredRule]
                                      }, null, 8, ["modelValue", "rules"])
                                    ]),
                                    _: 1
                                  }),
                                  _createVNode(_component_v_col, { cols: "4" }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_v_text_field, {
                                        label: "Nachname",
                                        modelValue: _ctx.person.lastname,
                                        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.person.lastname) = $event)),
                                        required: "",
                                        rules: [_ctx.requiredRule]
                                      }, null, 8, ["modelValue", "rules"])
                                    ]),
                                    _: 1
                                  }),
                                  _createVNode(_component_v_col, { cols: "6" }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_v_text_field, {
                                        label: "Geburtsdatum",
                                        modelValue: _ctx.person.birthday,
                                        "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.person.birthday) = $event)),
                                        type: "date",
                                        required: "",
                                        rules: [_ctx.requiredRule]
                                      }, null, 8, ["modelValue", "rules"])
                                    ]),
                                    _: 1
                                  }),
                                  _createVNode(_component_v_col, { cols: "6" }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_v_text_field, {
                                        label: "Geburtsort",
                                        modelValue: _ctx.person.birthplace,
                                        "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.person.birthplace) = $event)),
                                        required: "",
                                        rules: [_ctx.requiredRule]
                                      }, null, 8, ["modelValue", "rules"])
                                    ]),
                                    _: 1
                                  }),
                                  _createVNode(_component_v_col, { cols: "6" }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_v_text_field, {
                                        label: "Telefonnummer",
                                        modelValue: _ctx.person.phonenumber,
                                        "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.person.phonenumber) = $event))
                                      }, null, 8, ["modelValue"])
                                    ]),
                                    _: 1
                                  }),
                                  _createVNode(_component_v_col, { cols: "6" }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_v_text_field, {
                                        label: "Personalausweisnummer",
                                        modelValue: _ctx.person.idcard,
                                        "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.person.idcard) = $event))
                                      }, null, 8, ["modelValue"])
                                    ]),
                                    _: 1
                                  }),
                                  _createVNode(_component_v_col, { cols: "12" }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_v_text_field, {
                                        label: "Adresse",
                                        modelValue: _ctx.person.address,
                                        "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.person.address) = $event))
                                      }, null, 8, ["modelValue"])
                                    ]),
                                    _: 1
                                  }),
                                  _createVNode(_component_v_col, { cols: "12" }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_v_text_field, {
                                        label: "Bankkonto",
                                        modelValue: _ctx.person.bankaccount,
                                        "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.person.bankaccount) = $event))
                                      }, null, 8, ["modelValue"])
                                    ]),
                                    _: 1
                                  }),
                                  _createVNode(_component_v_col, { cols: "12" }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_v_text_field, {
                                        label: "E-Mail",
                                        modelValue: _ctx.person.mail,
                                        "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.person.mail) = $event))
                                      }, null, 8, ["modelValue"])
                                    ]),
                                    _: 1
                                  }),
                                  _createVNode(_component_v_col, { cols: "12" }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_v_text_field, {
                                        label: "Lizenzen",
                                        modelValue: _ctx.person.licenses,
                                        "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.person.licenses) = $event))
                                      }, null, 8, ["modelValue"])
                                    ]),
                                    _: 1
                                  }),
                                  _createVNode(_component_v_col, { cols: "12" }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_v_text_field, {
                                        label: "Einreise",
                                        modelValue: _ctx.person.entry,
                                        "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.person.entry) = $event)),
                                        type: "datetime-local"
                                      }, null, 8, ["modelValue"])
                                    ]),
                                    _: 1
                                  }),
                                  _createVNode(_component_v_col, { cols: "12" }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_v_checkbox, {
                                        modelValue: _ctx.person.wanted,
                                        "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.person.wanted) = $event)),
                                        label: "Gesucht"
                                      }, null, 8, ["modelValue"])
                                    ]),
                                    _: 1
                                  }),
                                  (_ctx.authority === 'fire')
                                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [], 64))
                                    : _createCommentVNode("", true),
                                  (_ctx.authority === 'police' || _ctx.authority === 'test')
                                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                                        _createVNode(_component_v_col, { cols: "12" }, {
                                          default: _withCtx(() => [
                                            _createVNode(_component_v_text_field, {
                                              label: "Letzter bekannter Ort",
                                              modelValue: _ctx.person.lastKnownLocation,
                                              "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.person.lastKnownLocation) = $event))
                                            }, null, 8, ["modelValue"])
                                          ]),
                                          _: 1
                                        }),
                                        _createVNode(_component_v_col, { cols: "12" }, {
                                          default: _withCtx(() => [
                                            _createVNode(_component_v_text_field, {
                                              label: "Gangangehörigkeit",
                                              modelValue: _ctx.person.gangAffiliation,
                                              "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.person.gangAffiliation) = $event))
                                            }, null, 8, ["modelValue"])
                                          ]),
                                          _: 1
                                        })
                                      ], 64))
                                    : _createCommentVNode("", true),
                                  (_ctx.authority === 'medic')
                                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                                        _createVNode(_component_v_col, { cols: "12" }, {
                                          default: _withCtx(() => [
                                            _createVNode(_component_v_text_field, {
                                              label: "Allergien",
                                              modelValue: _ctx.person.allergies,
                                              "onUpdate:modelValue": _cache[17] || (_cache[17] = ($event: any) => ((_ctx.person.allergies) = $event))
                                            }, null, 8, ["modelValue"])
                                          ]),
                                          _: 1
                                        }),
                                        _createVNode(_component_v_col, { cols: "12" }, {
                                          default: _withCtx(() => [
                                            _createVNode(_component_v_text_field, {
                                              label: "Notfallkontakt",
                                              modelValue: _ctx.person.emergencyContact,
                                              "onUpdate:modelValue": _cache[18] || (_cache[18] = ($event: any) => ((_ctx.person.emergencyContact) = $event))
                                            }, null, 8, ["modelValue"])
                                          ]),
                                          _: 1
                                        }),
                                        _createVNode(_component_v_col, { cols: "12" }, {
                                          default: _withCtx(() => [
                                            _createVNode(_component_v_text_field, {
                                              label: "Versichert?",
                                              modelValue: _ctx.person.insured,
                                              "onUpdate:modelValue": _cache[19] || (_cache[19] = ($event: any) => ((_ctx.person.insured) = $event))
                                            }, null, 8, ["modelValue"])
                                          ]),
                                          _: 1
                                        }),
                                        _createVNode(_component_v_col, { cols: "12" }, {
                                          default: _withCtx(() => [
                                            _createVNode(_component_v_text_field, {
                                              label: "Blutgruppe",
                                              modelValue: _ctx.person.bloodType,
                                              "onUpdate:modelValue": _cache[20] || (_cache[20] = ($event: any) => ((_ctx.person.bloodType) = $event))
                                            }, null, 8, ["modelValue"])
                                          ]),
                                          _: 1
                                        }),
                                        _createVNode(_component_v_col, { cols: "12" }, {
                                          default: _withCtx(() => [
                                            _createVNode(_component_v_text_field, {
                                              label: "Erste Hilfe Kurs?",
                                              modelValue: _ctx.person.firstAid,
                                              "onUpdate:modelValue": _cache[21] || (_cache[21] = ($event: any) => ((_ctx.person.firstAid) = $event))
                                            }, null, 8, ["modelValue"])
                                          ]),
                                          _: 1
                                        })
                                      ], 64))
                                    : _createCommentVNode("", true),
                                  (_ctx.authority === 'justice')
                                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 3 }, [
                                        _createVNode(_component_v_col, { cols: "12" }, {
                                          default: _withCtx(() => [
                                            _createVNode(_component_v_text_field, {
                                              label: "Letzter bekannter Ort",
                                              modelValue: _ctx.person.lastKnownLocation,
                                              "onUpdate:modelValue": _cache[22] || (_cache[22] = ($event: any) => ((_ctx.person.lastKnownLocation) = $event))
                                            }, null, 8, ["modelValue"])
                                          ]),
                                          _: 1
                                        }),
                                        _createVNode(_component_v_col, { cols: "12" }, {
                                          default: _withCtx(() => [
                                            _createVNode(_component_v_text_field, {
                                              label: "Gangangehörigkeit",
                                              modelValue: _ctx.person.gangAffiliation,
                                              "onUpdate:modelValue": _cache[23] || (_cache[23] = ($event: any) => ((_ctx.person.gangAffiliation) = $event))
                                            }, null, 8, ["modelValue"])
                                          ]),
                                          _: 1
                                        })
                                      ], 64))
                                    : _createCommentVNode("", true),
                                  (_ctx.authority === 'statepark')
                                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 4 }, [], 64))
                                    : _createCommentVNode("", true),
                                  (_ctx.authority === 'casa')
                                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 5 }, [
                                        _createVNode(_component_v_col, { cols: "12" }, {
                                          default: _withCtx(() => [
                                            _createVNode(_component_v_text_field, {
                                              label: "Beruf",
                                              modelValue: _ctx.person.job,
                                              "onUpdate:modelValue": _cache[24] || (_cache[24] = ($event: any) => ((_ctx.person.job) = $event))
                                            }, null, 8, ["modelValue"])
                                          ]),
                                          _: 1
                                        }),
                                        _createVNode(_component_v_col, { cols: "12" }, {
                                          default: _withCtx(() => [
                                            _createVNode(_component_v_text_field, {
                                              label: "Freiberufliche Tätigkeit",
                                              modelValue: _ctx.person.freelance,
                                              "onUpdate:modelValue": _cache[25] || (_cache[25] = ($event: any) => ((_ctx.person.freelance) = $event))
                                            }, null, 8, ["modelValue"])
                                          ]),
                                          _: 1
                                        })
                                      ], 64))
                                    : _createCommentVNode("", true)
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }, 512)
                ]),
                _: 1
              }),
              _createVNode(_component_v_window_item, { value: 2 }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_col, {
                    cols: "12",
                    class: "pa-4"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ckeditor, {
                        editor: _ctx.EditorClass,
                        config: _ctx.EditorClass.defaultConfig,
                        modelValue: _ctx.person.text,
                        "onUpdate:modelValue": _cache[26] || (_cache[26] = ($event: any) => ((_ctx.person.text) = $event))
                      }, null, 8, ["editor", "config", "modelValue"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["modelValue"]),
          _createVNode(_component_v_card_actions, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_spacer),
              _createVNode(_component_v_btn, {
                color: "blue darken-1",
                text: "",
                onClick: _ctx.closeDialog
              }, {
                default: _withCtx(() => [
                  _createTextVNode(" Abbrechen ")
                ]),
                _: 1
              }, 8, ["onClick"]),
              _createVNode(_component_v_btn, {
                color: "blue darken-1",
                text: "",
                onClick: _ctx.updatePerson,
                disabled: !_ctx.isFormValid(_ctx.person)
              }, {
                default: _withCtx(() => [
                  _createTextVNode(" Speichern ")
                ]),
                _: 1
              }, 8, ["onClick", "disabled"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }))
    : _createCommentVNode("", true)
}