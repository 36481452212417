<template>
	<v-app>
		<!-- Navigation Drawer -->
		<v-navigation-drawer
			app
			class="navbar-drawer-dark"
			dark
			v-model="drawer"
			v-if="isLoggedIn && !isLoadingUser"
		>
			<v-img
				:src="
					globalSettings.siteLogo
						? globalSettings.siteLogo
						: '/img/logo.png'
				"
				max-height="128"
				min-height="128"
				contain
			></v-img>
			<v-list density="compact" nav>
				<v-list-item
					prepend-icon="mdi-view-dashboard"
					title="Dashboard"
					to="/dashboard"
					:value="'/dashboard'"
					v-if="canAccessRoute('/dashboard')"
					:active="isActiveNav('/dashboard')"
				></v-list-item>
				<v-list-group
					value="Admin"
					prepend-icon="mdi-security"
					v-if="
						canAccessAnyRoute([
							'/admin/users',
							'/admin/roles',
							'/admin/employees',
							'/admin/trainings',
							'/admin/applicationquestions',
							'/admin/messages',
							'/admin/map',
							'/admin/weather',
							'/admin/settings',
						])
					"
				>
					<template v-slot:activator="{ props }">
						<v-list-item
							v-bind="props"
							prepend-icon="mdi-security"
							title="Admin"
						></v-list-item>
					</template>
					<v-list-item
						title="Benutzer"
						value="user"
						to="/admin/users"
						v-if="canAccessRoute('/admin/users')"
					></v-list-item>
					<v-list-item
						title="Rollen"
						value="roles"
						to="/admin/roles"
						v-if="canAccessRoute('/admin/roles')"
					></v-list-item>
					<v-list-item
						title="Schulungen"
						value="admin_training"
						to="/admin/trainings"
						v-if="canAccessRoute('/admin/trainings')"
					></v-list-item>
					<v-list-item
						title="Bewerberfragen"
						value="applicationquestions"
						to="/admin/applicationquestions"
						v-if="canAccessRoute('/admin/applicationquestions')"
					></v-list-item>
					<v-list-item
						title="Nachrichten"
						value="adminmessages"
						to="/admin/messages"
						v-if="canAccessRoute('/admin/messages')"
					></v-list-item>
					<v-list-item
						title="Karte"
						value="adminmap"
						to="/admin/map"
						v-if="canAccessRoute('/admin/map')"
					></v-list-item>
					<v-list-item
						title="Wetter"
						value="weather"
						to="/admin/weather"
						v-if="canAccessRoute('/admin/weather')"
					></v-list-item>
					<v-list-item
						title="Settings"
						value="settings"
						to="/admin/settings"
						v-if="canAccessRoute('/admin/settings')"
					></v-list-item>
					<v-divider></v-divider>
				</v-list-group>
				<v-list-subheader>Allgemein</v-list-subheader>
				<v-list-item
					prepend-icon="mdi-fire-truck"
					title="Leitstelle"
					to="/dispatch"
					:value="'/dispatch'"
					v-if="canAccessAnyRoute(['/dispatch', '/vehicle', '/crew'])"
					:active="isActiveNav('/dispatch')"
				></v-list-item>
				<v-list-item
					prepend-icon="mdi-developer-board"
					title="Schwarzes Brett"
					to="/blackboard/admin"
					:value="'/blackboard/admin'"
					v-if="
						canAccessAnyRoute([
							'/blackboard/admin',
							'/blackboard/employee',
						])
					"
					:active="isActiveNav('/blackboard/admin')"
				></v-list-item>
				<v-list-item
					prepend-icon="mdi-home-group"
					title="Behörden"
					to="/blackboard/global"
					:value="'/blackboard/global'"
					v-if="canAccessAnyRoute(['/company', '/companytype'])"
					:active="isActiveNav('/blackboard/global')"
				></v-list-item>
				<!-- Templates hinzugefügt -->
				<v-list-item
					prepend-icon="mdi-file-edit-outline"
					title="Templates"
					to="/template"
					value="template"
					v-if="canAccessRoute('/template')"
					:active="isActiveNav('/template')"
				></v-list-item>
				<v-list-subheader>Akten</v-list-subheader>
				<!-- Akten Menüpunkt hinzugefügt -->
				<v-list-item
					prepend-icon="mdi-folder"
					title="Akten"
					to="/person"
					:value="'/person'"
					v-if="
						canAccessAnyRoute([
							'/person',
							'/vehicleFile',
							'/apartmentFile',
						])
					"
					:active="isActiveNav('/person')"
				></v-list-item>
				<v-list-item
					prepend-icon="mdi-account-group"
					title="Mitarbeiter"
					to="/employee"
					:value="'/employee'"
					v-if="canAccessAnyRoute(['/employee', '/vacation'])"
					:active="isActiveNav('/employee')"
				></v-list-item>
				<v-list-item
					prepend-icon="mdi-domain"
					title="Unternehmen"
					to="/company"
					:value="'/company'"
					v-if="canAccessAnyRoute(['/company', '/companytype'])"
					:active="isActiveNav('/company')"
				></v-list-item>
				<v-list-subheader>Dokumente</v-list-subheader>
				<v-list-item
					prepend-icon="mdi-file-document-outline"
					title="Berichte"
					to="/report"
					:value="'/report'"
					v-if="
						canAccessAnyRoute([
							'/report',
							'/reportcategory',
							'/reporttemplate',
							'/reportcode',
							'/reportadditional',
							'/reportstatus',
						])
					"
					:active="isActiveNav('/report')"
				></v-list-item>
				<v-list-item
					prepend-icon="mdi-file-document-outline"
					title="Dokumente"
					to="/document"
					:value="'/document'"
					v-if="
						canAccessAnyRoute([
							'/department',
							'/document',
							'/training',
							'/administration',
						])
					"
					:active="isActiveNav('/document')"
				></v-list-item>
				<v-list-subheader>Sonstiges</v-list-subheader>
				<v-list-item
					prepend-icon="mdi-format-list-checks"
					title="Organisation"
					to="/todo"
					:value="'/todo'"
					v-if="
						canAccessAnyRoute([
							'/todo',
							'/calendar',
							'/application',
							'/report',
						])
					"
					:active="isActiveNav('/todo')"
				></v-list-item>
				<v-list-item
					prepend-icon="mdi-book-open"
					title="Schulungen"
					to="/trainingassign"
					:value="'/trainingassign'"
					v-if="canAccessAnyRoute(['/trainingassign', '/test'])"
					:active="isActiveNav('/trainingassign')"
				></v-list-item>
				<v-list-item
					prepend-icon="mdi-fire"
					title="Sonstiges"
					to="/fireprotection"
					:value="'/fireprotection'"
					:active="isActiveNav('/fireprotection')"
				></v-list-item>
			</v-list>
		</v-navigation-drawer>

		<!-- App Bar -->
		<v-app-bar app dark v-if="isLoggedIn">
			<v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
			<v-toolbar-title>{{
				globalSettings.siteName ||
				"Kyuubi D. Dragon Administration Tool"
			}}</v-toolbar-title>
			<v-spacer></v-spacer>
			<v-btn icon @click="goToMessages">
				<v-badge :content="unreadMessagesCount" color="red">
					<v-icon>mdi-email</v-icon>
				</v-badge>
			</v-btn>
			<v-avatar>
				<img src="https://via.placeholder.com/40" alt="Profile Image" />
			</v-avatar>
		</v-app-bar>

		<!-- Hauptinhalt -->
		<v-main id="web-body">
			<!-- Dynamische Tabs-Leiste direkt über dem Router-View -->
			<v-card v-if="showTabs" class="pa-2 mb-2" id="top-navbar">
				<v-tabs v-model="activeTab" background-color="primary" dark>
					<v-tab
						v-for="tab in filteredTabs"
						:key="tab.title"
						:to="tab.route"
					>
						{{ tab.title }}
					</v-tab>
				</v-tabs>
			</v-card>
			<router-view />
		</v-main>

		<!-- Snackbar für Benachrichtigungen -->
		<v-snackbar
			v-for="(notification, index) in notifications"
			:key="index"
			v-model="notification.visible"
			location="bottom right"
			timeout="5000"
			multi-line
			elevation="24"
			:stacked="true"
		>
			<div>
				<div>Neue Nachricht</div>
				<div>Von: {{ notification.senderName }}</div>
				<div>Titel: {{ notification.title }}</div>
			</div>
		</v-snackbar>
	</v-app>
</template>

<script lang="ts">
import { defineComponent, ref, computed, watch, onMounted } from "vue";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";
import api from "@/api";
import { isTokenExpired } from "@/store";
import { GlobalSettings } from "@/types/Settings";

export default defineComponent({
	name: "App",
	setup() {
		const drawer = ref(true);
		const store = useStore();
		const router = useRouter();
		const route = useRoute();
		const globalSettings = ref({});
		const notifications = ref([]);
		const activeTab = ref(0);
		const socket = ref<WebSocket | null>(null);

        const authority = computed(() => store.state.user ? store.state.user.authority : '');
        const userProfile = computed(() => store.state.user || {});

		const isLoggedIn = computed(() => !!store.state.jwt);
		const unreadMessagesCount = computed(
			() => store.state.user?.unreadMessagesCount || 0
		);

		// Definiere die Tabs für spezifische Basis-Pfade
		const tabs = {
			"/dispatch": [
				{ title: "Leitstelle", route: "/dispatch" },
				{ title: "Fahrzeuge", route: "/vehicle" },
				{ title: "Besatzung", route: "/crew" },
			],
			"/blackboard/admin": [
				{ title: "Schwarzes Brett", route: "/blackboard/admin" },
				{ title: "Mitarbeiter Brett", route: "/blackboard/employee" },
			],
			"/employee": [
				{ title: "Mitarbeiter", route: "/employee" },
				{ title: "Urlaube", route: "/vacation" },
			],
			"/report": [
				{ title: "Berichte", route: "/report" },
				{ title: "Kategorien", route: "/reportcategory" },
				{ title: "Vorlagen", route: "/reporttemplate" },
				{ title: "Code", route: "/reportcode" },
				{ title: "Zusatz", route: "/reportadditional" },
				{ title: "Status", route: "/reportstatus" },
			],
			"/blackboard/global": [
				{ title: "Schwarzes Brett", route: "/blackboard/global" },
				{ title: "Dokumente", route: "/document/global" },
				{ title: "Karte", route: "/map/global" },
			],
			"/company": [
				{ title: "Unternehmen", route: "/company" },
				{ title: "Typ", route: "/companytype" },
			],
			"/todo": [
				{ title: "To-Do", route: "/todo" },
				{ title: "Kalender", route: "/calendar" },
				{ title: "Bewerbungen", route: "/application" },
			],
			"/document": [
				{ title: "Abteilung", route: "/department" },
				{ title: "Dokumente", route: "/document" },
				{ title: "Schulungen", route: "/training" },
				{ title: "Verwaltung", route: "/administration" },
			],
			"/trainingassign": [
				{ title: "Übersicht", route: "/trainingassign" },
				{ title: "Test Generieren", route: "/test" },
			],
			"/fireprotection": [
				{ title: "Fire Protection", route: "/fireprotection" },
				{ title: "Cheatsheet", route: "/cheatsheet" },
			],
			// Tabs für "Akten" hinzugefügt
			"/person": [
				{ title: "Personen", route: "/person" },
				{ title: "Fahrzeuge", route: "/vehicleFile" },
				{ title: "Wohnungen", route: "/apartmentFile" },
			],
		};

		// Mapping von Pfaden zu ihrem Basis-Pfad
		const basePathMapping = {
			"/dispatch": "/dispatch",
			"/vehicle": "/dispatch",
			"/crew": "/dispatch",
			"/blackboard/admin": "/blackboard/admin",
			"/blackboard/employee": "/blackboard/admin",
			"/employee": "/employee",
			"/vacation": "/employee",
			"/report": "/report",
			"/reportcategory": "/report",
			"/reporttemplate": "/report",
			"/reportcode": "/report",
			"/reportadditional": "/report",
			"/reportstatus": "/report",
			"/blackboard/global": "/blackboard/global",
			"/document/global": "/blackboard/global",
			"/map/global": "/blackboard/global",
			"/company": "/company",
			"/companytype": "/company",
			"/todo": "/todo",
			"/calendar": "/todo",
			"/application": "/todo",
			"/department": "/document",
			"/document": "/document",
			"/training": "/document",
			"/administration": "/document",
			"/trainingassign": "/trainingassign",
			"/test": "/trainingassign",
			"/fireprotection": "/fireprotection",
			"/cheatsheet": "/fireprotection",
			// BasePathMapping für "Akten" hinzugefügt
			"/person": "/person",
			"/vehicleFile": "/person",
			"/apartmentFile": "/person",
		};

		const basePath = computed(() => basePathMapping[route.path] || "");

		// Berechne die Tabs basierend auf dem Basis-Pfad und filtere sie basierend auf Berechtigungen
		const filteredTabs = computed(() => {
			return basePath.value
				? tabs[basePath.value].filter((tab) =>
						canAccessRoute(tab.route)
				)
				: [];
		});
		const showTabs = computed(() => filteredTabs.value.length > 0);

		// Beobachte die Route und setze den aktiven Tab zurück
		watch(
			() => route.path,
			(newPath) => {
				const tabIndex = filteredTabs.value.findIndex(
					(tab) => tab.route === newPath
				);
				activeTab.value = tabIndex >= 0 ? tabIndex : 0;
			},
			{ immediate: true }
		);

		const goToMessages = () => router.push("/message");

		const isActiveNav = (basePath) => route.path.startsWith(basePath);

		onMounted(async () => {
			await fetchSettings();
			if (store.state.jwt) setupWebSocket();
		});

		// WebSocket und globale Einstellungen
		const setupWebSocket = () => {
            const token = store.state.jwt;
            if (!token) return;

            socket.value = new WebSocket(`wss://system.kyuubiddragon.com/restapi/ws/?token=${token}`);

            socket.value.onopen = () => {
                console.log('WebSocket connected');
            };

            socket.value.onmessage = (event) => {
                const data = JSON.parse(event.data);
                if (data.type === 'update' && data.site === 'message') {
                    fetchUnreadMessagesCount();
                    showNotification(data.title, data.sender_name);
                }
                console.log('WebSocket message received:', data);
            };

            socket.value.onerror = (error) => {
                console.error('WebSocket error:', error);
            };

            socket.value.onclose = () => {
                console.log('WebSocket disconnected');
                // Automatically attempt to reconnect after 5 seconds
                setTimeout(setupWebSocket, 5000);
            };
        };

        const fetchGlobalSettings = async () => {
            try {
                const response = await api.get("settings/?action=getGlobalSettings");
                if (response.data && response.data.settings) {
                    return response.data.settings;
                }
            } catch (error) {
                console.error("Error fetching global settings:", error);
            }
            return {};
        };

		const fetchSettings = async () => {
            const settings = await fetchGlobalSettings();
            globalSettings.value = settings;
        };

		// Berechtigungsüberprüfung
		const canAccessRoute = (routeName) => {
			const route = router.options.routes.find(
				(r) => r.path === routeName
			);
			if (route && route.meta) {
				const { requiredPermission, requiredSite } = route.meta;
				const userPermissions = store.state.user.permissions;
				const userAuthority = store.state.user.authority;

				const hasPermission =
					!requiredPermission ||
					(Array.isArray(userPermissions) &&
						(userPermissions.includes(requiredPermission) ||
							userPermissions.includes("ALL_PERMISSIONS")));
				const hasSiteAccess =
					!requiredSite ||
					(Array.isArray(requiredSite) &&
						requiredSite.includes(userAuthority));

				return hasPermission && hasSiteAccess;
			}
			return false;
		};

		const canAccessAnyRoute = (routes) =>
			routes.some((route) => canAccessRoute(route));


			const setHtmlClass = (newClass: string) => {
            const htmlElement = document.documentElement;

            // Clear all existing classes
            htmlElement.className = '';

            // Add the new class only if it's not empty
            if (newClass) {
                htmlElement.classList.add(newClass);
            }
        };


        // Beobachte Änderungen der `authority` und setze die Klasse entsprechend
        watch(authority, (newAuthority) => {
            setHtmlClass(newAuthority);
        });

        const fetchUnreadMessagesCount = async () => {
            try {
                const response = await api.get("message/?action=getUnreadMessagesCount");
                store.dispatch('updateUnreadMessagesCount', response.data.unread_count);
            } catch (error) {
                console.error("Error fetching unread messages count:", error);
            }
        };

        const goToProfile = () => {
            router.push('/profile');
        };

        const editProfile = () => {
            router.push('/edit-profile');
        };

        const logout = async () => {
            try {
                await api.post("account/?action=logout");
                store.dispatch('logout');
                router.push({ name: 'login' });
            } catch (error) {
                console.error('Error logging out:', error);
            }
        };

        const showNotification = (title, senderName) => {
            notifications.value.push({ title, senderName, visible: true });
        };

        onMounted(async () => {
            await fetchSettings();
            await fetchUnreadMessagesCount();
            if (store.state.jwt && !isTokenExpired(store.state)) {
                setupWebSocket();
            }
            setHtmlClass(authority.value);
        });

        // Prüfe Zugriff auf eine der gegebenen Routen

		return {
			drawer,
			isLoggedIn,
			globalSettings,
			unreadMessagesCount,
			goToMessages,
			notifications,
			filteredTabs,
			showTabs,
			activeTab,
			canAccessRoute,
			canAccessAnyRoute,
			isActiveNav,
		};
	},
});
</script>

<style lang="scss">
#web-body {
	background-image: url("@/assets/background.jpg");
	background-size: cover;
	background-position: center center;
	background-repeat: no-repeat;
	background-attachment: fixed;
	min-height: 100vh;
}

.disable-scroll {
	overflow: hidden;
	height: 100%;
}

.v-snackbar--multi-line .v-snackbar__wrapper {
	background-color: #212121;
	color: #ffffffab;
	border-radius: 8px;
}

.ck-editor__editable {
	resize: vertical;
	overflow: auto;
	min-height: 300px;
	padding: 30px;
}
.ck.ck-editor__main > .ck-editor__editable {
	padding-left: 30px;
}
.ck.ck-editor__top.ck-reset_all {
	z-index: var(--ck-z-modal);
	position: -webkit-sticky; /* Für Safari-Browser */
	position: sticky;
	top: 0;
	width: 100%;
	background-color: var(--ck-color-toolbar-background);
}

.v-toolbar__content,
.v-toolbar__extension,
.v-navigation-drawer__content {
	background-color: var(--primary);
}
button,
html [type="button"],
[type="reset"],
[type="submit"] {
	background-color: var(--primary);
	color: white;
}
.v-btn__content {
	color: white;
}
.text-info {
	color: var(--primary) !important;
}

.v-btn--icon.v-btn--density-default {
	background-color: #ffffff00 !important;
}

#top-navbar {
	padding: 17px 15px 5px 15px !important;
	background-color: #f0f8ff00 !important; /* Stellen Sie sicher, dass die Farbe transparent ist */
	box-shadow: none !important;
}

#top-navbar .v-tabs {
	height: 48px !important;
	border-radius: 7px;
	background-color: #212121 !important;
}
#top-navbar .v-slide-group__content {
	background-color: none !important;
}
</style>
