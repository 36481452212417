<template>
	<!-- Wohnung bearbeiten Formular -->
	<v-card v-if="apartmentToEdit">
		<v-card-title>Wohnung bearbeiten</v-card-title>
		<!-- Tabs für Wohnungsinformationen -->
		<v-tabs v-model="currentTab" background-color="primary" dark>
			<v-tab :value="1">Wohnungsdetails</v-tab>
			<v-tab :value="2">Zusätzliche Informationen</v-tab>
		</v-tabs>
		<v-window v-model="currentTab">
			<!-- Tab 1: Wohnungsdetails -->
			<v-window-item :value="1">
				<v-form ref="form" class="pa-4">
					<v-row>
						<!-- Linke Seite -->
						<v-col cols="12">
							<!-- Zeile: Wohnungsname -->
							<v-row>
								<v-col cols="12">
									<v-text-field
										label="Wohnungsname"
										v-model="apartment.name"
										required
										:rules="[requiredRule]"
									></v-text-field>
								</v-col>
							</v-row>

							<!-- Zeile: Ort, Straße, Hausnummer -->
							<v-row>
								<v-col cols="5">
									<v-text-field
										label="Ort"
										v-model="apartment.location"
										required
										:rules="[requiredRule]"
									></v-text-field>
								</v-col>
								<v-col cols="5">
									<v-text-field
										label="Straße"
										v-model="apartment.street"
										required
										:rules="[requiredRule]"
									></v-text-field>
								</v-col>
								<v-col cols="2">
									<v-text-field
										label="Hausnummer"
										v-model="apartment.housenumber"
										required
										:rules="[requiredRule]"
									></v-text-field>
								</v-col>
							</v-row>

							<!-- Zeile: Anzahl der Einheiten -->
							<v-row>
								<v-col cols="12">
									<v-text-field
										label="Anzahl der Einheiten"
										v-model="apartment.units"
										type="number"
										required
										:rules="[requiredRule]"
									></v-text-field>
								</v-col>
							</v-row>

							<!-- Zeile: Besitzer und Vermieter -->
							<v-row>
								<v-col cols="6">
									<v-select
										label="Besitzer"
										v-model="apartment.owners"
										:items="persons"
										item-title="fullname"
										item-value="id"
										required
										multiple
										chips
										:rules="[requiredRule]"
									></v-select>
								</v-col>
								<v-col cols="6">
									<v-select
										label="Vermieter"
										v-model="apartment.landlords"
										:items="persons"
										item-title="fullname"
										item-value="id"
										multiple
										chips
									></v-select>
								</v-col>
							</v-row>

							<!-- Zeile: Mieter und Untermieter -->
							<v-row>
								<v-col cols="6">
									<v-select
										label="Mieter"
										v-model="apartment.tenants"
										:items="persons"
										item-title="fullname"
										item-value="id"
										multiple
										chips
									></v-select>
								</v-col>
								<v-col cols="6">
									<v-select
										label="Untermieter"
										v-model="apartment.subtenants"
										:items="persons"
										item-title="fullname"
										item-value="id"
										multiple
										chips
									></v-select>
								</v-col>
							</v-row>
						</v-col>

					</v-row>
				</v-form>
			</v-window-item>

			<!-- Tab 2: Zusätzliche Informationen -->
			<v-window-item :value="2">
				<v-col cols="12" class="pa-4">
					<ckeditor
						:editor="EditorClass"
						:config="EditorClass.defaultConfig"
						v-model="apartment.text"
					></ckeditor>
				</v-col>
			</v-window-item>
		</v-window>

		<!-- Aktionsbuttons -->
		<v-card-actions>
			<v-spacer></v-spacer>
			<v-btn color="blue darken-1" text @click="closeDialog">
				Abbrechen
			</v-btn>
			<v-btn
				color="blue darken-1"
				text
				@click="updateApartment"
				:disabled="!isFormValid(apartment)"
			>
				Speichern
			</v-btn>
		</v-card-actions>
	</v-card>
</template>

<script lang="ts">
import { defineComponent, ref, computed, onMounted, watch, PropType } from "vue";
import { ApartmentWithRelations } from "@/types/Apartment"; // Verwende den richtigen Typ
import { PersonFile } from "@/types/Person";
import EditorClass from "@/ckeditor/ckeditor.js";
import api from "@/api";

export default defineComponent({
	name: "PoliceEditApartmentFile",
	props: {
		modelValue: {
			type: Boolean,
			required: true,
		},
		apartmentToEdit: {
			type: Object as PropType<ApartmentWithRelations>, // Verwende ApartmentWithRelations
			required: true,
		},
		editApartmentDialog: {
			type: Boolean,
			required: true,
		},
	},
	emits: ["update:modelValue", "apartmentUpdated", 'close'],
	setup(props, { emit }) {
		const dialog = computed({
			get: () => props.modelValue,
			set: (value) => {
				emit("update:modelValue", value);
			},
		});

		const apartment = ref<ApartmentWithRelations>({
			...props.apartmentToEdit,
			owners: [],
			tenants: [],
			landlords: [],
			subtenants: [],
		});

		const persons = ref<PersonFile[]>([]);
		const currentTab = ref(1);

		const fetchPersons = async () => {
			try {
				const response = await api.get("personfile/?action=getPersons");
				persons.value = response.data.map((person: PersonFile) => ({
					...person,
					id: Number(person.id), // Stelle sicher, dass die ID als Zahl gespeichert ist
					fullname: `${person.firstname} ${person.lastname}`,
				}));
			} catch (error) {
				console.error("Fehler beim Laden der Personen:", error);
			}
		};

		watch(
			() => props.apartmentToEdit,
			(newVal) => {
				if (newVal) {
					apartment.value = {
						...newVal,
						owners: newVal.owners.map(Number),
						tenants: newVal.tenants.map(Number),
						landlords: newVal.landlords.map(Number),
						subtenants: newVal.subtenants.map(Number),
					};
				}
			},
			{ immediate: true }
		);

		const requiredRule = (value: string) => !!value || "Dieses Feld ist erforderlich.";

		const updateApartment = async () => {
			try {
				const payload = {
					...apartment.value,
					owners: apartment.value.owners,
					tenants: apartment.value.tenants,
					landlords: apartment.value.landlords,
					subtenants: apartment.value.subtenants,
				};
				await api.post("apartmentfile/?action=editApartment", payload);
				emit("apartmentUpdated");
				closeDialog();
			} catch (error) {
				console.error("Fehler beim Bearbeiten der Wohnung:", error);
			}
		};

		const isFormValid = (form: ApartmentWithRelations) => {
			return (
				form.name !== "" &&
				form.location !== "" &&
				form.street !== "" &&
				form.housenumber !== ""
			);
		};

		const closeDialog = () => {
			dialog.value = false;
            emit("close");
		};

		onMounted(fetchPersons);

		return {
			dialog,
			apartment,
			persons,
			requiredRule,
			updateApartment,
			isFormValid,
			closeDialog,
			EditorClass,
			currentTab,
		};
	},
});
</script>
