<template>
	<!-- Fahrzeugdetails anzeigen -->
	<v-card v-if="vehicleToView">
		<v-card-title>Fahrzeugdetails</v-card-title>
		<!-- Tabs für Fahrzeuginformationen -->
		<v-tabs v-model="currentTab" background-color="primary" dark>
			<v-tab :value="1">Details</v-tab>
			<v-tab :value="2">Besitzer & Fahrer</v-tab>
			<v-tab :value="3">Beschreibung</v-tab>
		</v-tabs>
		<v-window v-model="currentTab">
			<!-- Tab 1: Fahrzeugdetails -->
			<v-window-item :value="1">
				<v-form ref="form" class="pa-4">
					<v-row>
						<v-col cols="6">
							<v-text-field
								label="Marke"
								v-model="vehicle.brand"
								disabled
							></v-text-field>
						</v-col>
						<v-col cols="6">
							<v-text-field
								label="Modell"
								v-model="vehicle.model"
								disabled
							></v-text-field>
						</v-col>
						<v-col cols="6">
							<v-text-field
								label="Kennzeichen"
								v-model="vehicle.numberplate"
								disabled
							></v-text-field>
						</v-col>
						<v-col cols="6">
							<v-text-field
								label="Farbe"
								v-model="vehicle.color"
								disabled
							></v-text-field>
						</v-col>
						<v-col cols="6">
							<v-checkbox
								v-model="vehicle.stolen"
								label="Gestohlen"
								disabled
							></v-checkbox>
						</v-col>
						<v-col cols="6">
							<v-checkbox
								v-model="vehicle.wanted"
								label="Gesucht"
								disabled
							></v-checkbox>
						</v-col>
						<v-col cols="12">
							<v-text-field
								label="Registrierungsdatum"
								v-model="vehicle.registered"
								disabled
							></v-text-field>
						</v-col>
					</v-row>
				</v-form>
			</v-window-item>

			<!-- Tab 2: Besitzer & Fahrer -->
			<v-window-item :value="2">
				<v-row>
					<v-col cols="12">
						<h4>Besitzer</h4>
						<v-data-table
							:headers="personHeaders"
							:items="getPersonsByIds(vehicle.owners)"
							:items-per-page="5"
						>
							<template v-slot:[`item.actions`]="{ item }">
								<v-btn
									icon
									@click="openPersonView(item)"
									title="Anzeigen"
								>
									<v-icon>mdi-eye</v-icon>
								</v-btn>
							</template>
						</v-data-table>
					</v-col>
					<v-col cols="12" class="mt-4">
						<h4>Fahrer</h4>
						<v-data-table
							:headers="personHeaders"
							:items="getPersonsByIds(vehicle.drivers)"
							:items-per-page="5"
						>
							<template v-slot:[`item.actions`]="{ item }">
								<v-btn
									icon
									@click="openPersonView(item)"
									title="Anzeigen"
								>
									<v-icon>mdi-eye</v-icon>
								</v-btn>
							</template>
						</v-data-table>
					</v-col>
				</v-row>
			</v-window-item>

			<!-- Tab 3: Beschreibung -->
			<v-window-item :value="3">
				<v-col cols="12" class="mt-4 pa-4">
					<h4>Beschreibung</h4>
					<div v-html="vehicle.text"></div>
				</v-col>
			</v-window-item>
		</v-window>

		<!-- Aktionsbuttons -->
		<v-card-actions>
			<v-spacer></v-spacer>
			<v-btn color="blue darken-1" text @click="closeDialog">
				Schließen
			</v-btn>
		</v-card-actions>

		<!-- PersonView Component -->
		<component
			:is="viewPersonComponent"
			v-model="viewPersonDialog"
			:personToView="selectedPerson"
			@close="viewPersonClose"
		/>
	</v-card>
</template>

<script lang="ts">
import { defineComponent, ref, computed, watch, PropType } from "vue";
import { VehicleFile } from "@/types/Vehicle";
import { PersonFile } from "@/types/Person";
import api from "@/api";
import AuthorityViewPersonFile from "@/components/PersonFile/Authority/View.vue";

export default defineComponent({
	name: "VehicleView",
	props: {
		modelValue: {
			type: Boolean,
			required: true,
		},
		vehicleToView: {
			type: Object as PropType<VehicleFile>,
			required: true,
		},
	},
	emits: ["update:modelValue", 'close'],
	setup(props, { emit }) {
		const dialog = computed({
			get: () => props.modelValue,
			set: (value) => {
				emit("update:modelValue", value);
			},
		});

		const vehicle = ref<VehicleFile>({
			...props.vehicleToView,
			owners: props.vehicleToView?.owners || [],
			drivers: props.vehicleToView?.drivers || [],
		});

		const persons = ref<PersonFile[]>([]);
		const personHeaders = [
			{ title: "Name", value: "name" },
			{ title: "Telefonnummer", value: "phonenumber" },
			{ title: "E-Mail", value: "mail" },
			{ title: "Aktionen", value: "actions", sortable: false },
		];

		const selectedPerson = ref<PersonFile | null>(null);
		const viewPersonDialog = ref(false);

		const viewPersonComponent = computed(() => {
			return AuthorityViewPersonFile;
		});

		const currentTab = ref(1);

		const fetchPersons = async () => {
			try {
				const response = await api.get(
					"vehiclefile/?action=getPersons"
				);
				persons.value = response.data.map((person: PersonFile) => ({
					...person,
					name: `${person.firstname} ${person.lastname}`,
				}));
			} catch (error) {
				console.error("Fehler beim Laden der Personen:", error);
			}
		};

		const viewPersonClose = () => {
			selectedPerson.value = null;
			viewPersonDialog.value = false;
		};

		// Update vehicle data when vehicleToView prop changes
		watch(
			() => props.vehicleToView,
			(newVehicle) => {
				if (newVehicle) {
					vehicle.value = {
						...newVehicle,
						owners: newVehicle.owners || [],
						drivers: newVehicle.drivers || [],
					};
				}
				fetchPersons();
			},
			{ immediate: true }
		);

		// Get persons based on their IDs
		const getPersonsByIds = (ids: number[]) => {
			return persons.value.filter((person) => ids.includes(person.id));
		};

		const openPersonView = (person: PersonFile) => {
			selectedPerson.value = person;
			viewPersonDialog.value = true;
		};

		const closeDialog = () => {
			dialog.value = false;
			emit("close");
		};

		return {
			dialog,
			vehicle,
			persons,
			personHeaders,
			viewPersonComponent,
			viewPersonDialog,
			selectedPerson,
			getPersonsByIds,
			openPersonView,
			closeDialog,
			currentTab,
			viewPersonClose
		};
	},
});
</script>
