<template>
    <error-snackbar v-model:snackbar="errorSnackbar" />
        <v-row justify="space-around" class="mt-2">
            <h1>Willkommen auf der Startseite vom LSFD</h1>
            <v-divider class="border-opacity-50" ></v-divider>
        </v-row>
        <v-container>
            <v-row>
                <!--Calendar Today-->
                <v-col cols="4" mb="4" >
                    <v-card width="100%" class=" mt-4">
                        <v-img
                        v-if="EventsToday.length > 0"
                        height="157"
                        src="../assets/calendarOverviewTop400.png"
                        cover
                        class="text-white"
                        >
                        <v-toolbar
                            color="rgba(0, 0, 0, 0)"
                            theme="dark"
                        >
                            <v-toolbar-title class="text-h6" style="font-color:black">
                                Heutige Termine
                            </v-toolbar-title>

                        </v-toolbar>
                        </v-img>
                        <v-img
                        v-else
                        height="157"
                        src="../assets/calendarOverviewTop400Free_2.png"
                        cover
                        class="text-white"
                        >
                        <v-toolbar
                            color="rgba(0, 0, 0, 0)"
                            theme="dark"
                        >
                            <v-toolbar-title class="text-h6">
                                Heutige Termine
                            </v-toolbar-title>

                        </v-toolbar>
                        </v-img>

                        <v-card-text>
                        <div class="font-weight-bold ms-1 mb-2">
                            Today
                        </div>

                        <v-timeline density="compact" align="start" v-if="EventsToday.length > 0">
                            <v-timeline-item
                            v-for="event in EventsToday"
                            :key="formatDateCalPreview(event.start)"
                            :dot-color="event.color"
                            size="x-small"
                            >
                            <div class="mb-4">
                                <div class="font-weight-normal">
                                <strong>{{ isUserAssigned(event.assigned_to) }} 
                                    <v-chip v-if="Object.keys(event.assigned_to).length > 1" size="x-small" class="ma-2">{{ '+' + (Object.keys(event.assigned_to).length - 1) }} 

                                    </v-chip>
                                </strong> @{{ formatDateCalPreview(event.start) }}
                                <v-tooltip
                                    activator="parent"
                                    location="top"
                                    v-if="Object.keys(event.assigned_to).length > 1"
                                    >
                                
                                        <v-chip variant="text" v-for="user in event.assigned_to" :key="user.user_id">
                                            {{ user.username }}
                                        </v-chip>
                                    </v-tooltip>
                                </div>
                                <div>{{ event.title }}</div>
                            </div>
                            </v-timeline-item>
                        </v-timeline>
                        <v-timeline density="compact" align="start" v-else>
                            <v-timeline-item
                            dot-color="#00000"
                            size="x-small"
                            >
                            <div class="mb-4">
                                <div class="font-weight-normal">
                                <strong>Heute stehen keine Termine an. ☀️🏖️</strong>
                                </div>
                            </div>
                            </v-timeline-item>
                        </v-timeline>
                        </v-card-text>
                    </v-card>

                    <v-card width="100%" class=" mt-5">
                        <v-img
                        v-if="Events7Days.length > 0"
                        height="157"
                        src="../assets/calendarOverviewTop400.png"
                        cover
                        >
                        <v-toolbar
                            color="rgba(0, 0, 0, 0)"
                            theme="dark"
                        >
                            <v-toolbar-title class="text-h6">
                                Termine der nächsten 7 Tage
                            </v-toolbar-title>

                        </v-toolbar>
                        </v-img>
                        <v-img
                        v-else
                        height="157"
                        src="../assets/calendarOverviewTop400Free_2.png"
                        cover
                        class="text-white"
                        >
                        <v-toolbar
                            color="rgba(0, 0, 0, 0)"
                            theme="dark"
                        >
                            <v-toolbar-title class="text-h6">
                                Termine der nächsten 7 Tage
                            </v-toolbar-title>

                        </v-toolbar>
                        </v-img>

                        <v-card-text>
                        <div class="font-weight-bold ms-1 mb-2">
                            Nächste 7 Tage
                        </div>

                        <v-timeline density="compact" align="start" v-if="Events7Days.length > 0">
                            <v-timeline-item
                            v-for="event in Events7Days"
                            :key="event.start"
                            :dot-color="event.color"
                            size="x-small"
                            >
                            <div class="mb-4">
                                <div class="font-weight-normal">
                                <strong>{{ isUserAssigned(event.assigned_to) }} 
                                    <v-chip v-if="Object.keys(event.assigned_to).length > 1" size="x-small" class="ma-2">{{ '+' + (Object.keys(event.assigned_to).length - 1) }} 

                                    </v-chip>
                                </strong> @{{ formatDateCalPreview(event.start) }} - {{ formatDateToDDMMYYYY(formatDate(event.start, false, true)) }}
                                <v-tooltip
                                    activator="parent"
                                    location="top"
                                    v-if="Object.keys(event.assigned_to).length > 1"
                                    >
                                
                                        <v-chip variant="text" v-for="user in event.assigned_to" :key="user.user_id">
                                            {{ user.username }}
                                        </v-chip>
                                    </v-tooltip>
                                </div>
                                <div>{{ event.title }}</div>
                            </div>
                            </v-timeline-item>
                        </v-timeline>
                        <v-timeline density="compact" align="start" v-else>
                            <v-timeline-item
                            dot-color="#00000"
                            size="x-small"
                            >
                            <div class="mb-4">
                                <div class="font-weight-normal">
                                <strong>In den nächsten 7 Tagen sind keine Termine. ☀️🏖️</strong>
                                </div>
                            </div>
                            </v-timeline-item>
                        </v-timeline>
                        </v-card-text>
                    </v-card>
                </v-col>
                <!--Todo Today-->
                <v-col cols="4" mb="4" >
                    <v-card width="100%" class=" mt-4">
                        <v-img
                        v-if="TodosToday.length > 0"
                        height="157"
                        src="../assets/todoOverviewTop.png"
                        cover
                        class="text-white"
                        >
                        <v-toolbar
                            color="rgba(0, 0, 0, 0)"
                            theme="dark"
                        >
                            <v-toolbar-title class="text-h6" style="font-color:black">
                                Heutige Todos
                            </v-toolbar-title>

                        </v-toolbar>
                        </v-img>
                        <v-img
                        v-else
                        height="157"
                        src="../assets/calendarOverviewTop400Free_2.png"
                        cover
                        class="text-white"
                        >
                        <v-toolbar
                            color="rgba(0, 0, 0, 0)"
                            theme="dark"
                        >
                            <v-toolbar-title class="text-h6">
                                Heutige Todos
                            </v-toolbar-title>

                        </v-toolbar>
                        </v-img>

                        <v-card-text>
                        <div class="font-weight-bold ms-1 mb-2">
                            Today
                        </div>

                        <v-timeline density="compact" align="start" v-if="TodosToday.length > 0">
                            <v-timeline-item
                            v-for="todo in TodosToday"
                            :key="todo.id"
                            :dot-color="getDotColor(todo.importance)"
                            size="x-small"
                            >
                            <div class="mb-4">
                                <div class="font-weight-normal">
                                <strong>{{ todo.MainTodo }} 
                                </strong> @ {{ todo.SubTodo }}

                                </div>
                                <div>{{ todo.TodoPoint }}</div>
                            </div>
                            </v-timeline-item>
                        </v-timeline>
                        <v-timeline density="compact" align="start" v-else>
                            <v-timeline-item
                            dot-color="#00000"
                            size="x-small"
                            >
                            <div class="mb-4">
                                <div class="font-weight-normal">
                                <strong>Heute stehen keine Todos an. ☀️🏖️</strong>
                                </div>
                            </div>
                            </v-timeline-item>
                        </v-timeline>
                        </v-card-text>
                    </v-card>
                    <v-card width="100%" class=" mt-5">
                        <v-img
                        v-if="Todos7Days.length > 0"
                        height="157"
                        src="../assets/todoOverviewTop.png"
                        cover
                        class="text-white"
                        >
                        <v-toolbar
                            color="rgba(0, 0, 0, 0)"
                            theme="dark"
                        >
                            <v-toolbar-title class="text-h6" style="font-color:black">
                                Todos in den nächsten 7 Tagen.
                            </v-toolbar-title>

                        </v-toolbar>
                        </v-img>
                        <v-img
                        v-else
                        height="157"
                        src="../assets/calendarOverviewTop400Free_2.png"
                        cover
                        class="text-white"
                        >
                        <v-toolbar
                            color="rgba(0, 0, 0, 0)"
                            theme="dark"
                        >
                            <v-toolbar-title class="text-h6">
                                Todos der nächsten 7 Tage
                            </v-toolbar-title>

                        </v-toolbar>
                        </v-img>

                        <v-card-text>
                        <div class="font-weight-bold ms-1 mb-2">
                            Nächste 7 Tage
                        </div>

                        <v-timeline density="compact" align="start" v-if="Todos7Days.length > 0">
                            <v-timeline-item
                            v-for="todo in Todos7Days"
                            :key="todo.id"
                            :dot-color="getDotColor(todo.importance)"
                            size="x-small"
                            >
                            <div class="mb-4">
                                <div class="font-weight-normal">
                                <strong>{{ todo.MainTodo }} 
                                </strong> @ {{ todo.SubTodo }}

                                </div>
                                <div>{{ todo.TodoPoint }}</div>
                            </div>
                            </v-timeline-item>
                        </v-timeline>
                        <v-timeline density="compact" align="start" v-else>
                            <v-timeline-item
                            dot-color="#00000"
                            size="x-small"
                            >
                            <div class="mb-4">
                                <div class="font-weight-normal">
                                <strong>In den nächsten 7 Tagen stehen keine Todos an. ☀️🏖️</strong>
                                </div>
                            </div>
                            </v-timeline-item>
                        </v-timeline>
                        </v-card-text>
                    </v-card>
                </v-col>
                <!--Ausstehende Bewerbungen-->
                <v-col cols="4" mb="4">
                    <v-card width="100%" class=" mt-4">
                        <v-img
                        v-if="Applicants.length > 0"
                        height="157"
                        src="../assets/todoOverviewTop.png"
                        cover
                        class="text-white"
                        >
                        <v-toolbar
                            color="rgba(0, 0, 0, 0)"
                            theme="dark"
                        >
                            <v-toolbar-title class="text-h6" style="font-color:black">
                                Ausstehende Bewerbungen
                            </v-toolbar-title>

                        </v-toolbar>
                        </v-img>
                        <v-img
                        v-else
                        height="157"
                        src="../assets/calendarOverviewTop400Free_2.png"
                        cover
                        class="text-white"
                        >
                        <v-toolbar
                            color="rgba(0, 0, 0, 0)"
                            theme="dark"
                        >
                            <v-toolbar-title class="text-h6">
                                Ausstehende Bewerbungen
                            </v-toolbar-title>

                        </v-toolbar>
                        </v-img>

                        <v-card-text>
                        <div class="font-weight-bold ms-1 mb-2">
                            Bewerbungen
                        </div>

                        <v-timeline density="compact" align="start" v-if="Applicants.length > 0">
                            <v-timeline-item
                            v-for="Applicant in Applicants"
                            :key="Applicant.id"
                            dot-color="green"
                            size="x-small"
                            >
                            <div class="mb-4">
                                <div class="font-weight-normal">
                                <strong>{{ Applicant.name }} 
                                </strong> @{{ Applicant.jobinterviewTime.substring(0,5) }} - {{ formatDateToDDMMYYYY(Applicant.jobinterviewDate) }}

                                </div>
                                <div>{{ Applicant.email }}, {{ Applicant.phonenumber }}</div>
                            </div>
                            </v-timeline-item>
                        </v-timeline>
                        <v-timeline density="compact" align="start" v-else>
                            <v-timeline-item
                            dot-color="#00000"
                            size="x-small"
                            >
                            <div class="mb-4">
                                <div class="font-weight-normal">
                                <strong>Es stehen keine Bewerber an. ☀️🏖️</strong>
                                </div>
                            </div>
                            </v-timeline-item>
                        </v-timeline>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
</template>

<script lang="ts">
import api from "@/api";
import { defineComponent, ref, onMounted, computed } from "vue";
import { EventOverview } from "@/types/Calendar";
import { TodoOverview } from "@/types/Todo";
import { ApplicantPending } from "@/types/Application"
import "vue-cal/dist/vuecal.css";
import { useStore } from 'vuex';

export default defineComponent({
  components: {
  },
    setup() {
        const errorSnackbar = ref<any>({ visible: false, message: "" });
        const store = useStore();
        const Events7Days = ref<EventOverview[]>([]);
        const EventsToday = ref<EventOverview[]>([]);
        const Todos7Days = ref<TodoOverview[]>([]);
        const TodosToday = ref<TodoOverview[]>([]);
        const Applicants = ref<ApplicantPending[]>([]);


        onMounted(async () => {
            await fetchEvents();
            await fetchTodos();
            await fetchPendingApplicant();
        });


        // methods
        async function fetchEvents() {
            try {
                const next7days = await api.get('calendar/?action=getEvents7Days');
                const today = await api.get('calendar/?action=getEventsToday');
                Events7Days.value = next7days.data; // Speichern Sie die ursprüngliche Ereignisliste in originalEvents
                EventsToday.value = today.data;

            } catch (error) {
                errorSnackbar.value.message = error.response.data.error;
                errorSnackbar.value.visible  = true;	
            }
        }

        async function fetchTodos() {
            try {
                const next7days = await api.get('todo/?action=getTodos7Days');
                const today = await api.get('todo/?action=getTodosToday');
                Todos7Days.value = next7days.data; // Speichern Sie die ursprüngliche Ereignisliste in originalEvents
                TodosToday.value = today.data;
            } catch (error) {
                errorSnackbar.value.message = error.response.data.error;
                errorSnackbar.value.visible  = true;	
            }
        }

        async function fetchPendingApplicant() {
            try {
                const response = await api.get('application/?action=getPendingApplicant');
                Applicants.value = response.data; // Speichern Sie die ursprüngliche Ereignisliste in originalEvents
            } catch (error) {
                errorSnackbar.value.message = error.response.data.error;
                errorSnackbar.value.visible  = true;
            }
        }



        function formatDate(dateInput: string | Date, isStart: boolean, onlyDate = false) {
            const date = new Date(dateInput);
            if (!isStart) {
                date.setHours(date.getHours() + 1);
            }

            const timezoneOffset = date.getTimezoneOffset() * 60000;
            const localISOTime = new Date(date.getTime() - timezoneOffset).toISOString().slice(0, -1);

            const [datePart, timePart] = localISOTime.split('T');
            let [hour, minute] = timePart.split(':');

            // Runde die Minuten auf die nächsten 15-Minuten-Schritte
            const roundedMinute = Math.ceil(parseInt(minute) / 15) * 15;
            if (roundedMinute === 60) {
                // Wenn die gerundeten Minuten 60 sind, erhöhe die Stunden um 1 und setze die Minuten auf 0
                hour = (parseInt(hour) + 1).toString().padStart(2, "0");
                minute = "00";
            } else {
                minute = roundedMinute.toString().padStart(2, "0");
            }

            return onlyDate ? datePart : `${datePart}T${hour}:${minute}`;
        }

        function formatDateToDDMMYYYY(datePart: any) {
            let [year, month, day] = datePart.split('-');
            const formattedDate = `${day}.${month}.${year}`;
            return formattedDate;
        }

        function isUserAssigned(users: any){
            if(Object.keys(users).length == 0) return "Fire Department";
            if(users.some(user => user.user_id === parseInt(store.state.user.id))) return "You"
        }

        function formatDateCalPreview(dateInput: string | Date) {
            const date = new Date(dateInput);
            const hours = date.getHours().toString().padStart(2, '0');
            const minutes = date.getMinutes().toString().padStart(2, '0');
            return `${hours}:${minutes}`;
        }

        function getDotColor(importance) {
            switch (importance) {
                case 'low':
                return 'green';
                case 'medium':
                return 'orange';
                case 'high':
                return 'red';
                default:
                return 'blue'; // Fallback-Farbe, falls keine Übereinstimmung gefunden wird
            }
        }

        return {
            Events7Days,
            EventsToday,
            formatDateCalPreview,
            store,
            isUserAssigned,
            formatDateToDDMMYYYY,
            formatDate,
            TodosToday, 
            Todos7Days,
            getDotColor,
            Applicants,
            errorSnackbar,
        };
    },
});
</script>

<style scoped>

</style>