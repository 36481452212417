<template>
	<!-- Neue Wohnung hinzufügen Formular -->
	<v-card v-if="addApartmentDialog">
		<v-card-title>Neue Wohnung hinzufügen</v-card-title>
		<!-- Tabs für Wohnungsinformationen -->
		<v-tabs v-model="currentTab" background-color="primary" dark>
			<v-tab :value="1">Wohnungsdetails</v-tab>
			<v-tab :value="2">Zusätzliche Informationen</v-tab>
		</v-tabs>
		<v-window v-model="currentTab">
			<!-- Tab 1: Wohnungsdetails -->
			<v-window-item :value="1">
				<v-form ref="form" class="pa-4">
					<v-row>
						<!-- Linke Seite -->
						<v-col cols="12">
							<!-- Name -->
							<v-row>
								<v-col cols="12">
									<v-text-field
										label="Wohnungsname"
										v-model="apartment.name"
										required
										:rules="[requiredRule]"
									></v-text-field>
								</v-col>
							</v-row>

							<!-- Ort, Straße, Hausnummer -->
							<v-row>
								<v-col cols="5">
									<v-text-field
										label="Ort"
										v-model="apartment.location"
										required
										:rules="[requiredRule]"
									></v-text-field>
								</v-col>
								<v-col cols="5">
									<v-text-field
										label="Straße"
										v-model="apartment.street"
										required
										:rules="[requiredRule]"
									></v-text-field>
								</v-col>
								<v-col cols="2">
									<v-text-field
										label="Hausnummer"
										v-model="apartment.housenumber"
										required
										:rules="[requiredRule]"
									></v-text-field>
								</v-col>
							</v-row>

							<!-- Anzahl der Einheiten -->
							<v-row>
								<v-col cols="12">
									<v-text-field
										label="Anzahl der Einheiten"
										v-model="apartment.units"
										type="number"
										required
										:rules="[requiredRule]"
									></v-text-field>
								</v-col>
							</v-row>

							<!-- Besitzer und Vermieter -->
							<v-row>
								<v-col cols="6">
									<v-select
										label="Besitzer"
										v-model="apartment.owners"
										:items="persons"
										item-title="fullname"
										item-value="id"
										required
										multiple
										chips
										:rules="[requiredRule]"
									></v-select>
								</v-col>
								<v-col cols="6">
									<v-select
										label="Vermieter"
										v-model="apartment.landlords"
										:items="persons"
										item-title="fullname"
										item-value="id"
										multiple
										chips
									></v-select>
								</v-col>
							</v-row>

							<!-- Mieter und Untermieter -->
							<v-row>
								<v-col cols="6">
									<v-select
										label="Mieter"
										v-model="apartment.tenants"
										:items="persons"
										item-title="fullname"
										item-value="id"
										multiple
										chips
									></v-select>
								</v-col>
								<v-col cols="6">
									<v-select
										label="Untermieter"
										v-model="apartment.subtenants"
										:items="persons"
										item-title="fullname"
										item-value="id"
										multiple
										chips
									></v-select>
								</v-col>
							</v-row>
						</v-col>
					</v-row>
				</v-form>
			</v-window-item>

			<!-- Tab 2: Zusätzliche Informationen -->
			<v-window-item :value="2">
				<v-col cols="12" class="pa-4">
					<ckeditor
						:editor="EditorClass"
						:config="EditorClass.defaultConfig"
						v-model="apartment.text"
					></ckeditor>
				</v-col>
			</v-window-item>
		</v-window>

		<!-- Aktionsbuttons -->
		<v-card-actions>
			<v-spacer></v-spacer>
			<v-btn color="blue darken-1" text @click="closeDialog">
				Abbrechen
			</v-btn>
			<v-btn
				color="blue darken-1"
				text
				@click="addNewApartment"
				:disabled="!isFormValid(apartment)"
			>
				Hinzufügen
			</v-btn>
		</v-card-actions>
	</v-card>
</template>

<script lang="ts">
import EditorClass from "@/ckeditor/ckeditor.js";
import { defineComponent, ref, computed, onMounted } from "vue";
import { Apartment } from "@/types/Apartment";
import { PersonFile } from "@/types/Person";
import api from "@/api";

export default defineComponent({
	name: "PoliceAddApartmentFile",
	props: {
		modelValue: {
			type: Boolean,
			required: true,
		},
		addApartmentDialog: {
			type: Boolean,
			required: true,
		},
	},
	emits: ["update:modelValue", "apartmentAdded", 'close'],
	setup(props, { emit }) {
		const dialog = computed({
			get: () => props.modelValue,
			set: (value) => {
				emit("update:modelValue", value);
			},
		});

		const apartment = ref<Apartment>({
			id: 0,
			name: "",
			location: "",
			street: "",
			housenumber: "",
			units: 0,
			text: "",
			owners: [],
			tenants: [],
			landlords: [],
			subtenants: [],
			bought: false,
			rented: false,
		});

		const persons = ref<PersonFile[]>([]);
		const currentTab = ref(1);

		const fetchPersons = async () => {
			try {
				const response = await api.get("personfile/?action=getPersons");
				persons.value = response.data.map((person) => ({
					...person,
					fullname: `${person.firstname} ${person.lastname}`,
				}));
			} catch (error) {
				console.error("Fehler beim Laden der Personen:", error);
			}
		};

		const requiredRule = (value: string) =>
			!!value || "Dieses Feld ist erforderlich.";

		const addNewApartment = async () => {
			try {
				await api.post(
					"apartmentfile/?action=addApartment",
					apartment.value
				);
				emit("apartmentAdded");
				closeDialog();
			} catch (error) {
				console.error("Fehler beim Hinzufügen der Wohnung:", error);
			}
		};

		const isFormValid = (form: Apartment) => {
			return (
				form.name !== "" &&
				form.location !== "" &&
				form.street !== "" &&
				form.housenumber !== ""
			);
		};

		const closeDialog = () => {
			dialog.value = false;
			emit("close");
		};

		onMounted(fetchPersons);

		return {
			dialog,
			apartment,
			requiredRule,
			addNewApartment,
			isFormValid,
			closeDialog,
			EditorClass,
			persons,
			currentTab,
		};
	},
});
</script>
