// CompanyView.vue
<template>
  <v-container>
    <v-text-field
      v-if="isSite('fire').value"
      append-inner-icon="mdi-content-save"
      density="compact"
      label="Feuerlöschernummer"
      variant="solo"
      hide-details
      v-model="extinguisherNr"
      style="width:300px"
      class="float-left"
      @click:append-inner="updateFireExtinguisherNr"
    ></v-text-field>
    <v-btn @click="openNewCompanyDialog" class="float-right" v-if="!newCompanyDialog && !editCompanyDialog && !viewCompanyDialog">Neue Firma</v-btn>
    <v-data-table
      :headers="companyHeaders"
      :items="filteredCompanies"
      class="elevation-1 mt-12"
      :search="search"
      :items-per-page="25"
      :group-by="groupBy"
      v-if="!newCompanyDialog && !editCompanyDialog && !viewCompanyDialog"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Firmenverwaltung</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-select
            class="mx-4 mt-3"
            v-model="filters.expiryFilter"
            :items="expiryFilterOptions"
            label="Filter nach Ablaufdatum"
            variant="underlined"
            clearable
          ></v-select>
          <v-text-field v-model="search" label="Suche" class="mx-4 mt-3" variant="underlined" />
        </v-toolbar>
      </template>
      <template v-slot:group-header="{ item, columns, toggleGroup, isGroupOpen }">
        <tr>
          <td :colspan="columns.length">
            <VBtn
              :icon="isGroupOpen(item) ? '$expand' : '$next'"
              size="small"
              variant="text"
              @click="toggleGroup(item)"
            ></VBtn>
            {{ item.value }}
          </td>
        </tr>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon size="small" class="me-2" @click="openEditCompanyDialog(item)">mdi-pencil</v-icon>
        <v-icon size="small" class="me-2" @click="openDeleteCompanyDialog(item)">mdi-delete</v-icon>
        <v-icon size="small" class="me-2" @click="viewCompanyDetails(item)">mdi-eye</v-icon>
      </template>
      <template v-slot:[`item.last_fire_protection_inspection`]="{ item }">
        {{ formatDate(item.last_fire_protection_inspection) }}
      </template>
      <template v-slot:[`item.fire_protection_inspection_valid_until`]="{ item }">
        {{ formatDate(item.fire_protection_inspection_valid_until) }}
      </template>
    </v-data-table>

    <!-- New Company Dialog -->
    <AuthorityAddCompanyFile v-model="newCompanyDialog" :newCompanyDialog="newCompanyDialog" @companyAdded="fetchCompanies" />

    <!-- Edit Company Dialog -->
    <AuthorityEditCompanyFile v-model="editCompanyDialog" :editCompanyDialog="editCompanyDialog" :companyToEdit="editedCompany" @companyUpdated="fetchCompanies" />

    <!-- View Company Dialog -->
    <AuthorityViewCompanyFile v-model="viewCompanyDialog" :viewCompanyDialog="viewCompanyDialog" :companyToView="selectedCompany" />

    <!-- Snackbar -->
    <v-snackbar v-model="snackbar.value" :color="snackbar.color" :timeout="4000">
      {{ snackbar.message }}
    </v-snackbar>
  </v-container>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref, computed } from 'vue';
import api from '@/apiWithoutHeaders';
import { Company, CompanyType } from '@/types/Company';
import { useStore } from 'vuex';
import AuthorityAddCompanyFile from '@/components/CompanyFile/Authority/Add.vue';
import AuthorityEditCompanyFile from '@/components/CompanyFile/Authority/Edit.vue';
import AuthorityViewCompanyFile from '@/components/CompanyFile/Authority/View.vue';

export default defineComponent({
  components: {
    AuthorityAddCompanyFile,
    AuthorityEditCompanyFile,
    AuthorityViewCompanyFile,
  },
  setup() {
    const store = useStore();
    const extinguisherNr = ref('');

    const companyHeaders = [
      { title: 'Name', align: 'start', sortable: false, key: 'name' },
      { title: 'Leitstellennummer', align: 'start', sortable: false, key: 'phonenumber' },
      { title: 'CEO', align: 'start', sortable: false, key: 'ceo' },
      { title: 'Anzahl Feuerlöscher', align: 'start', sortable: false, key: 'extinguisher_count' },
      { title: 'Letzte BSB', align: 'start', sortable: false, key: 'last_fire_protection_inspection' },
      { title: 'Nächste BSB', align: 'start', sortable: false, key: 'fire_protection_inspection_valid_until' },
      { title: 'Aktionen', key: 'actions', sortable: false },
    ];

    const groupBy = [
      {
        key: 'type_name',
        order: 'asc',
      },
    ];

    const companies = ref<Company[]>([]);
    const companyTypes = ref<CompanyType[]>([]);
    const newCompanyDialog = ref(false);
    const editCompanyDialog = ref(false);
    const viewCompanyDialog = ref(false);
    const editedCompany = ref<Company | null>(null);
    const selectedCompany = ref<Company | null>(null);
    const search = ref('');
    const snackbar = ref({
      value: false,
      color: '',
      message: '',
    });

    const requiredRule = (value: string) => !!value || 'Dieses Feld ist erforderlich.';

    const fetchCompanies = async () => {
      try {
          const response = await api.get('company/?action=getCompanies');
          companies.value = response.data.map(entry => ({
              ...entry,
              contract_available: entry.contract_available == '1' ? true : false,
          }));
      } catch (error) {
        console.error("Fehler beim Laden der Firmen:", error);
      }
    };

    const filters = ref({
      expiryFilter: null as string | null,
    });

    const expiryFilterOptions = [
      { title: 'Nächsten 30 Tage', value: 'next-30-days' },
      { title: 'Letzten 30 Tage', value: 'last-30-days' },
      { title: 'Nicht verfügbar', value: 'not-available' },
    ];

    const filteredCompanies = computed(() => {
      return companies.value
        .filter(company => {
          const matchesSearch = search.value ? company.name.toLowerCase().includes(search.value.toLowerCase()) : true;
          const matchesExpiryFilter = matchExpiryFilter(company);
          return matchesSearch && matchesExpiryFilter;
        });
    });

    const matchExpiryFilter = (company: Company) => {
      const today = new Date();
      const dateValidUntil = new Date(company.fire_protection_inspection_valid_until);
      switch (filters.value.expiryFilter) {
        case 'next-30-days':
          return dateValidUntil >= today && dateValidUntil <= new Date(today.setDate(today.getDate() + 30));
        case 'last-30-days':
          return dateValidUntil < today && dateValidUntil >= new Date(today.setDate(today.getDate() - 30));
        case 'not-available':
          return company.fire_protection_inspection_valid_until === '0000-00-00';
        default:
          return true;
      }
    };

    const isSite = (site) => {
      return computed(() => store.state.user.authority == site);
    }

    const updateFireExtinguisherNr = async () => {
      try {
        await api.post('company/?action=setFireExtinguisherNr', { extinguisherNr: extinguisherNr.value });
        await fetchExtinguisherNr();
      } catch (error) {
        console.error("Fehler beim Aktualisieren der Feuerlöschernummer:", error);
      }
    };

    const fetchExtinguisherNr = async () => {
      try {
        const response = await api.get('company/?action=getFireExtinguisherNr');
        extinguisherNr.value = response.data.extinguisherNr;
      } catch (error) {
        console.error("Fehler beim Laden der Feuerlöschernummer:", error);
      }
    };

    const openNewCompanyDialog = () => {
      newCompanyDialog.value = true;
    };

    const openEditCompanyDialog = (company: Company) => {
      editedCompany.value = { ...company };
      editCompanyDialog.value = true;
    };

    const openDeleteCompanyDialog = (company: Company) => {
      selectedCompany.value = company;
      // Implement delete logic here
    };

    const viewCompanyDetails = (company: Company) => {
      selectedCompany.value = { ...company };
      viewCompanyDialog.value = true;
    };

    function formatDate(date: any) {
      if (!date) return "";
      const [datePart] = date.split(" ");
      const [year, month, day] = datePart.split("-");
      return `${day}.${month}.${year}`;
    }

    onMounted(async () => {
      await fetchCompanies();
      await fetchExtinguisherNr();
    });

    return {
      companyHeaders,
      companies,
      newCompanyDialog,
      editCompanyDialog,
      viewCompanyDialog,
      editedCompany,
      selectedCompany,
      search,
      snackbar,
      requiredRule,
      extinguisherNr,
      updateFireExtinguisherNr,
      filters,
      expiryFilterOptions,
      filteredCompanies,
      matchExpiryFilter,
      groupBy,
      isSite,
      formatDate,
      openNewCompanyDialog,
      openEditCompanyDialog,
      openDeleteCompanyDialog,
      viewCompanyDetails,
      fetchCompanies
    };
  },
});
</script>

<style scoped>
.float-right {
  float: right;
}
</style>