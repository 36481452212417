import { createRouter, createWebHashHistory, createWebHistory, RouteRecordRaw } from "vue-router";
import { useStore } from "vuex";
import { computed } from "vue";
import store from "@/store";
import { isAuthenticated, State, isTokenExpired } from "@/store";
import HomeView from "@/views/HomeView.vue";
import EmployeeView from "@/views/EmployeeView.vue";
import VacationView from "@/views/VacationView.vue";
import ApplicationView from "@/views/ApplicationView.vue";
import CalendarView from "@/views/CalendarView.vue";
import DocumentView from "@/views/DocumentView.vue";
import FireprotectionView from "@/views/FireprotectionView.vue";
import TemplateView from "@/views/TemplateView.vue";
import PersonView from "@/views/PersonView.vue";
import VehicleFileView from "@/views/VehicleFileView.vue";
import ApartmentFileView from "@/views/ApartmentView.vue";
import TodoView from "@/views/TodoView.vue";
import LoginView from "@/views/LoginView.vue";
import ProfileView from "@/views/ProfileView.vue";
import TrainingView from "@/views/TrainingView.vue";
import AdminUserView from "@/views/admin/UserView.vue";
import AdminApplicationView from "@/views/admin/ApplicationQuestionsView.vue";
import AdminRolesView from "@/views/admin/RoleView.vue"
import MapView from "@/views/MapView.vue";
import AdminSettingsView from "@/views/admin/SettingsView.vue";
import AdminEmployeeView from "@/views/admin/EmployeeView.vue";
import AdminTrainingView from "@/views/admin/TrainingView.vue";
import AdminMapView from "@/views/admin/MapView.vue";
import CheatsheetView from "@/views/CheatsheetView.vue";
import TestView from "@/views/TestView.vue";
import MapOnlyView from "@/views/MapOnlyView.vue";
import BlackboardView from "@/views/BlackboardView.vue";
import DispatchView from "@/views/DispatchView.vue";
import VehicleView from "@/views/VehicleView.vue";
import CrewView from "@/views/CrewView.vue";
import ReportView from "@/views/ReportView.vue";
import ReportCategorieView from "@/views/ReportCategorieView.vue";
import ReportTemplateView from "@/views/ReportTemplateView.vue";
import ReportStatusView from "@/views/ReportStatusView.vue";
import ReportAdditionalView from "@/views/ReportAdditionalView.vue";
import MessageView from "@/views/MessageView.vue";
import AdminMessagesView from "@/views/admin/MessagesView.vue"
import ReportCodeView from "@/views/ReportCodeView.vue";
import CompanyView from "@/views/CompanyView.vue";
import CompanyTypeView from "@/views/CompanyTypeView.vue";
import DashboardView from "@/views/DashboardView.vue";
import AdminWeatherView from "@/views/admin/WeatherView.vue";

const routes: Array<RouteRecordRaw> = [
    {
        path: "/",
        name: "login",
        component: LoginView,
    },
    {
        path: "/home",
        name: "home",
        component: HomeView,
        meta: { requiresAuth: true },
    },
    {
        path: "/dashboard",
        name: "dashboard",
        component: DashboardView,
        meta: { requiresAuth: true },
    },
    {
        path: "/employee",
        name: "employee",
        component: EmployeeView,
        meta: { requiresAuth: true, requiredPermission: 'VIEW_EMPLOYEE', requiredSite: ['test', 'police', 'fire', 'casa', 'medic', 'justice', 'statepark']  },
    },
    {
        path: "/message",
        name: "message",
        component: MessageView,
        meta: { requiresAuth: true, requiredPermission: 'VIEW_MESSAGES', requiredSite: ['test', 'police', 'fire', 'casa', 'medic', 'justice', 'statepark']  },
    },
    {
        path: "/dispatch",
        name: "dispatch",
        component: DispatchView,
        meta: { requiresAuth: true, requiredPermission: 'VIEW_DISPATCH', requiredSite: ['test', 'police', 'fire', 'casa', 'medic', 'justice', 'statepark']  },
    },
    {
        path: "/vehicle",
        name: "vehicle",
        component: VehicleView,
        meta: { requiresAuth: true, requiredPermission: 'VIEW_VEHICLE', requiredSite: ['test', 'police', 'fire', 'casa', 'medic', 'justice', 'statepark']  },
    },
    {
        path: "/reportcategory",
        name: "reportcategory",
        component: ReportCategorieView,
        meta: { requiresAuth: true, requiredPermission: 'VIEW_REPORTCATEGORY', requiredSite: ['test', 'police', 'fire', 'casa', 'medic', 'justice', 'statepark']  },
    },
    {
        path: "/reporttemplate",
        name: "reporttemplate",
        component: ReportTemplateView,
        meta: { requiresAuth: true, requiredPermission: 'VIEW_REPORTTEMPLATE', requiredSite: ['test', 'police', 'fire', 'casa', 'medic', 'justice', 'statepark']  },
    },
    {
        path: "/reportstatus",
        name: "reportstatus",
        component: ReportStatusView,
        meta: { requiresAuth: true, requiredPermission: 'VIEW_REPORT_STATUS', requiredSite: ['test', 'police', 'fire', 'casa', 'medic', 'justice', 'statepark']  },
    },
    {
        path: "/report",
        name: "report",
        component: ReportView,
        meta: { requiresAuth: true, requiredPermission: 'VIEW_REPORT', requiredSite: ['test', 'police', 'fire', 'casa', 'medic', 'justice', 'statepark']  },
    },
    {
        path: "/reportcode",
        name: "reportcode",
        component: ReportCodeView,
        meta: { requiresAuth: true, requiredPermission: 'VIEW_REPORTCODE', requiredSite: ['test', 'police', 'fire', 'casa', 'medic', 'justice', 'statepark']  },
    },
    {
        path: "/crew",
        name: "crew",
        component: CrewView,
        meta: { requiresAuth: true, requiredPermission: 'VIEW_CREW', requiredSite: ['test', 'police', 'fire', 'casa', 'medic', 'justice', 'statepark']  },
    },
    {
        path: "/vacation",
        name: "vacation",
        component: VacationView,
        meta: { requiresAuth: true, requiredPermission: 'VIEW_EMPLOYEE', requiredSite: ['test', 'police', 'fire', 'casa', 'medic', 'justice', 'statepark']  },
    },
    {
        path: "/trainingassign",
        name: "trainingassign",
        component: TrainingView,
        meta: { requiresAuth: true, requiredPermission: 'VIEW_TRAININGASSIGN', requiredSite: ['test', 'police', 'fire', 'casa', 'medic', 'justice', 'statepark']  },
    },
    {
        path: "/reportadditional",
        name: "reportadditional",
        component: ReportAdditionalView,
        meta: { requiresAuth: true, requiredPermission: 'VIEW_REPORT_ADDITIONAL', requiredSite: ['test', 'police', 'fire', 'casa', 'medic', 'justice', 'statepark']  },
    },
    {
        path: "/application",
        name: "application",
        component: ApplicationView,
        meta: { requiresAuth: true, requiredPermission: 'VIEW_APPLICATION', requiredSite: ['test', 'police', 'fire', 'casa', 'medic', 'justice', 'statepark']  },
    },
    {
        path: "/calendar",
        name: "calendar",
        component: CalendarView,
        meta: { requiresAuth: true, requiredPermission: 'VIEW_CALENDAR', requiredSite: ['test', 'police', 'fire', 'casa', 'medic', 'justice', 'statepark']  },
    },
    {
        path: "/document",
        name: "documents",
        component: DocumentView,
        meta: { requiresAuth: true, requiredPermission: 'VIEW_DOCUMENT', docType: 'document', requiredSite: ['test', 'police', 'fire', 'casa', 'medic', 'justice', 'statepark']  },
    },
    {
        path: "/document/global",
        name: "documentsglobal",
        component: DocumentView,
        meta: { requiresAuth: true, requiredPermission: 'VIEW_DOCUMENT_GLOBAL', docType: 'global', requiredSite: ['test', 'police', 'fire', 'casa', 'medic', 'justice', 'statepark']  },
    },
    {
        path: "/test",
        name: "tests",
        component: TestView,
        meta: { requiresAuth: true, requiredPermission: 'VIEW_TEST', requiredSite: ['test', 'police', 'fire', 'casa', 'medic', 'justice', 'statepark'] },
    }, 
    {
        path: "/training",
        name: "trainings",
        component: DocumentView,
        meta: { requiresAuth: true, requiredPermission: 'VIEW_DOCUMENT_TRAINING', docType: 'training', requiredSite: ['test', 'police', 'fire', 'casa', 'medic', 'justice', 'statepark']  },
    }, 
    {
        path: "/department",
        name: "department",
        component: DocumentView,
        meta: { requiresAuth: true, requiredPermission: 'VIEW_DOCUMENT_DEPARTMENT', docType: 'department', requiredSite: ['test', 'police', 'fire', 'casa', 'medic', 'justice', 'statepark']  },
    }, 
    {
        path: "/administration",
        name: "administration",
        component: DocumentView,
        meta: { requiresAuth: true, requiredPermission: 'VIEW_DOCUMENT_ADMINISTRATION', docType: 'administration', requiredSite: ['test', 'police', 'fire', 'casa', 'medic', 'justice', 'statepark']  },
    }, 
    {
        path: "/fireprotection",
        name: "fireprotection",
        component: FireprotectionView,
        meta: { requiresAuth: true, requiredPermission: 'VIEW_FIREPROTECTION', requiredSite: ['test', 'fire']  },
    },
    {
        path: "/company",
        name: "company",
        component: CompanyView,
        meta: { requiresAuth: true, requiredPermission: 'VIEW_COMPANY', requiredSite: ['test', 'police', 'fire', 'casa', 'medic', 'justice', 'statepark']  },
    },
    {
        path: "/template",
        name: "template",
        component: TemplateView,
        meta: { requiresAuth: true, requiredPermission: 'VIEW_TEMPLATE', requiredSite: ['test', 'police', 'fire', 'casa', 'medic', 'justice', 'statepark']  },
    },
    {
        path: "/person",
        name: "person",
        component: PersonView,
        meta: { requiresAuth: true, requiredPermission: 'VIEW_PERSON', requiredSite: ['test', 'police', 'fire', 'casa', 'medic', 'justice', 'statepark']  },
    },
    {
        path: "/vehicleFile",
        name: "vehicleFile",
        component: VehicleFileView,
        meta: { requiresAuth: true, requiredPermission: 'VIEW_VEHICLE_FILE', requiredSite: ['test', 'police', 'fire', 'casa', 'medic', 'justice', 'statepark']  },
    },
    {
        path: "/apartmentFile",
        name: "apartmentFile",
        component: ApartmentFileView,
        meta: { requiresAuth: true, requiredPermission: 'VIEW_APARTMENT_FILE', requiredSite: ['test', 'police', 'fire', 'casa', 'medic', 'justice', 'statepark'] },
    },
    {
        path: "/blackboard/admin",
        name: "blackboardAdmin",
        component: BlackboardView,
        meta: { requiresAuth: true, requiredPermission: 'VIEW_BLACKBOARD_ADMIN', boardType: 'admin', requiredSite: ['test', 'police', 'fire', 'casa', 'medic', 'justice', 'statepark'] },
    },
    {
        path: "/blackboard/employee",
        name: "blackboardEmployee",
        component: BlackboardView,
        meta: { requiresAuth: true, requiredPermission: 'VIEW_BLACKBOARD_EMPLOYEE', boardType: 'employee', requiredSite: ['test', 'police', 'fire', 'casa', 'medic', 'justice', 'statepark'] },
    },
    {
        path: "/blackboard/global",
        name: "blackboardGlobal",
        component: BlackboardView,
        meta: { requiresAuth: true, requiredPermission: 'VIEW_BLACKBOARD_GLOBAL', boardType: 'global', requiredSite: ['test', 'police', 'fire', 'casa', 'medic', 'justice', 'statepark'] },
    },
    {
        path: "/companytype",
        name: "companytype",
        component: CompanyTypeView,
        meta: { requiresAuth: true, requiredPermission: 'VIEW_COMPANYTYPE', requiredSite: ['test', 'police', 'fire', 'casa', 'medic', 'justice', 'statepark'] },
    },
    {
        path: "/todo",
        name: "todo",
        component: TodoView,
        meta: { requiresAuth: true, requiredPermission: 'VIEW_TODO', requiredSite: ['test', 'police', 'fire', 'casa', 'medic', 'justice', 'statepark'] },
    },
    {
        path: '/profile',
        name: "profile",
        component: ProfileView,
        meta: { requiresAuth: true, requiredPermission: 'VIEW_ACCOUNT', requiredSite: ['test', 'police', 'fire', 'casa', 'medic', 'justice', 'statepark']  },
    },
    {
        path: '/admin/applicationquestions',
        name: "admin_applicationquestions",
        component: AdminApplicationView,
        meta: { requiresAuth: true, requiredPermission: 'ADMIN_VIEW_APPLICATION', requiredSite: ['test', 'police', 'fire', 'casa', 'medic', 'justice', 'statepark']  },
    },
    {
        path: '/admin/users',
        name: "admin_users",
        component: AdminUserView,
        meta: { requiresAuth: true, requiredPermission: 'ADMIN_VIEW_USERS', requiredSite: ['test', 'police', 'fire', 'casa', 'medic', 'justice', 'statepark']  },
    },
    {
        path: '/admin/roles',
        name: "admin_roles",
        component: AdminRolesView,
        meta: { requiresAuth: true, requiredPermission: 'ADMIN_VIEW_ROLES', requiredSite: ['test', 'police', 'fire', 'casa', 'medic', 'justice', 'statepark']  },
    },
    {
        path: '/admin/settings',
        name: "admin_settings",
        component: AdminSettingsView,
        meta: { requiresAuth: true, requiredPermission: 'ADMIN_VIEW_SETTINGS', requiredSite: ['test', 'police', 'fire', 'casa', 'medic', 'justice', 'statepark']  },
    },
    {
        path: '/admin/weather',
        name: "admin_weather",
        component: AdminWeatherView,
        meta: { requiresAuth: true, requiredPermission: 'ADMIN_VIEW_WEATHER', requiredSite: ['test', 'police', 'fire', 'casa', 'medic', 'justice', 'statepark']  },
    },
    {
        path: '/admin/employees',
        name: "admin_employee",
        component: AdminEmployeeView,
        meta: { requiresAuth: true, requiredPermission: 'ADMIN_VIEW_EMPLOYEE', requiredSite: ['test', 'police', 'fire', 'casa', 'medic', 'justice', 'statepark']  },
    },
    {
        path: '/admin/trainings',
        name: "admin_training",
        component: AdminTrainingView,
        meta: { requiresAuth: true, requiredPermission: 'ADMIN_VIEW_TRAINING', requiredSite: ['test', 'police', 'fire', 'casa', 'medic', 'justice', 'statepark']  },
    },
    {
        path: '/admin/map',
        name: "admin_map",
        component: AdminMapView,
        meta: { requiresAuth: true, requiredPermission: 'ADMIN_VIEW_MAP', requiredSite: ['test', 'police', 'fire', 'casa', 'medic', 'justice', 'statepark']  },
    },
    {
        path: '/admin/messages',
        name: "admin_messages",
        component: AdminMessagesView,
        meta: { requiresAuth: true, requiredPermission: 'ADMIN_VIEW_MESSAGES', requiredSite: ['test', 'police', 'fire', 'casa', 'medic', 'justice', 'statepark']  },
    },
    {
        path: '/map',
        name: "map",
        component: MapView,
        meta: { requiresAuth: true, requiredPermission: 'VIEW_MAP', requiredSite: ['test', 'police', 'fire', 'casa', 'medic', 'justice', 'statepark']  },
    },
    {
        path: '/map/global',
        name: "mapglobal",
        component: MapView,
        meta: { requiresAuth: true, requiredPermission: 'VIEW_MAP_GLOBAL', mapType: 'global', requiredSite: ['test', 'police', 'fire', 'casa', 'medic', 'justice', 'statepark']  },
    },
    {
        path: '/mapOnly',
        name: "mapOnly",
        component: MapOnlyView,
        meta: { requiresAuth: false, requiredSite: ['test', 'police', 'fire', 'casa', 'medic', 'justice', 'statepark']  },
    },
    {
        path: '/cheatsheet',
        name: "cheatsheet",
        component: CheatsheetView,
        meta: { requiresAuth: true, requiredSite: ['test', 'fire']  },
    }

];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

import api from "@/api";

declare module 'vue-router' {
    interface RouteMeta {
        // The `requiresAuth` meta property must be declared for every route
        requiresAuth: boolean;
        // Optional `requiredPermission` for permission checks
        requiredPermission?: string;
        // Optional `requiredSite` for site access checks
        requiredSite?: string[];
    }
}

router.beforeEach(async (to, from, next) => {
    const isUserAuthenticated = isAuthenticated(store.state as State);
    const isExpired = isTokenExpired(store.state as State);

    // Prüfe, ob der Benutzer authentifiziert ist
    if (isUserAuthenticated) {
        // Prüfe, ob das Token abgelaufen ist
        if (isExpired) {
            // Benutzer abmelden und zur Login-Seite umleiten
            await store.dispatch("logout");
            next({ name: "login" });
        } else {
            // Benutzer ist authentifiziert und Token ist gültig
            if (to.name === "login") {
                // Verhindere Zugriff auf Login-Seite, wenn bereits eingeloggt
                next({ name: "dashboard" });
            } else {
                try {
                    // Hol die neuesten Benutzerinformationen (Berechtigungen etc.)
                    const response = await api.post("account/?action=checkUser");
                    const { permissions, banned, authority } = response.data;

                    if (banned) {
                        // Benutzer ist gesperrt, abmelden und zur Login-Seite umleiten
                        store.dispatch("logout");
                        next({ name: "login" });
                    } else {
                        // Aktualisiere den Store mit Benutzerberechtigungen und Autorität
                        store.commit("setPermissions", permissions);
                        store.commit("setAuthority", authority);

                        const userProfile = computed(() => store.state.user);
                        const profile = userProfile.value;
                        // Überprüfe, ob der Benutzer die erforderlichen Berechtigungen hat
                        const userPermissions = store.state.user.permissions || [];
                        const userAuthority = store.state.user.authority;

                        // Prüfe Berechtigungen und Autorität
                        const hasPermission = !to.meta.requiredPermission ||
                            userPermissions.includes(to.meta.requiredPermission) ||
                            userPermissions.includes('ALL_PERMISSIONS');


                        console.log(to.meta.requiredSite);
                        console.log(profile)
                        console.log(!to.meta.requiredSite || to.meta.requiredSite.includes(profile.authority))
                        const hasSiteAccess = !to.meta.requiredSite || to.meta.requiredSite.includes(userAuthority);

                        // Wenn der Benutzer sowohl Berechtigung als auch Autorität hat, erlaube die Navigation
                        if (hasPermission && hasSiteAccess) {
                            next();
                        } else {
                            // Wenn Berechtigung fehlt, leite zum Dashboard oder einer "Zugriff verweigert"-Seite weiter
                            next({ name: "dashboard" });
                        }
                    }
                } catch (error) {
                    console.error("Error fetching permissions:", error);
                    // Bei Fehlern leite zur Dashboard-Seite weiter
                    next({ name: "dashboard" });
                }
            }
        }
    } else {
        // Wenn der Benutzer nicht authentifiziert ist, leite zur Login-Seite weiter, wenn Authentifizierung erforderlich ist
        if (to.meta.requiresAuth) {
            next({ name: "login" });
        } else {
            // Erlaube Zugriff auf öffentliche Seiten
            next();
        }
    }
});




export default router;
