import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_tab = _resolveComponent("v-tab")!
  const _component_v_tabs = _resolveComponent("v-tabs")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_select = _resolveComponent("v-select")!
  const _component_v_form = _resolveComponent("v-form")!
  const _component_v_window_item = _resolveComponent("v-window-item")!
  const _component_ckeditor = _resolveComponent("ckeditor")!
  const _component_v_window = _resolveComponent("v-window")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_card = _resolveComponent("v-card")!

  return (_ctx.apartmentToEdit)
    ? (_openBlock(), _createBlock(_component_v_card, { key: 0 }, {
        default: _withCtx(() => [
          _createVNode(_component_v_card_title, null, {
            default: _withCtx(() => [
              _createTextVNode("Wohnung bearbeiten")
            ]),
            _: 1
          }),
          _createVNode(_component_v_tabs, {
            modelValue: _ctx.currentTab,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.currentTab) = $event)),
            "background-color": "primary",
            dark: ""
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_tab, { value: 1 }, {
                default: _withCtx(() => [
                  _createTextVNode("Wohnungsdetails")
                ]),
                _: 1
              }),
              _createVNode(_component_v_tab, { value: 2 }, {
                default: _withCtx(() => [
                  _createTextVNode("Zusätzliche Informationen")
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["modelValue"]),
          _createVNode(_component_v_window, {
            modelValue: _ctx.currentTab,
            "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.currentTab) = $event))
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_window_item, { value: 1 }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_form, {
                    ref: "form",
                    class: "pa-4"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_row, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_col, { cols: "12" }, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_row, null, {
                                default: _withCtx(() => [
                                  _createVNode(_component_v_col, { cols: "12" }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_v_text_field, {
                                        label: "Wohnungsname",
                                        modelValue: _ctx.apartment.name,
                                        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.apartment.name) = $event)),
                                        required: "",
                                        rules: [_ctx.requiredRule]
                                      }, null, 8, ["modelValue", "rules"])
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_v_row, null, {
                                default: _withCtx(() => [
                                  _createVNode(_component_v_col, { cols: "5" }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_v_text_field, {
                                        label: "Ort",
                                        modelValue: _ctx.apartment.location,
                                        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.apartment.location) = $event)),
                                        required: "",
                                        rules: [_ctx.requiredRule]
                                      }, null, 8, ["modelValue", "rules"])
                                    ]),
                                    _: 1
                                  }),
                                  _createVNode(_component_v_col, { cols: "5" }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_v_text_field, {
                                        label: "Straße",
                                        modelValue: _ctx.apartment.street,
                                        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.apartment.street) = $event)),
                                        required: "",
                                        rules: [_ctx.requiredRule]
                                      }, null, 8, ["modelValue", "rules"])
                                    ]),
                                    _: 1
                                  }),
                                  _createVNode(_component_v_col, { cols: "2" }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_v_text_field, {
                                        label: "Hausnummer",
                                        modelValue: _ctx.apartment.housenumber,
                                        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.apartment.housenumber) = $event)),
                                        required: "",
                                        rules: [_ctx.requiredRule]
                                      }, null, 8, ["modelValue", "rules"])
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_v_row, null, {
                                default: _withCtx(() => [
                                  _createVNode(_component_v_col, { cols: "12" }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_v_text_field, {
                                        label: "Anzahl der Einheiten",
                                        modelValue: _ctx.apartment.units,
                                        "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.apartment.units) = $event)),
                                        type: "number",
                                        required: "",
                                        rules: [_ctx.requiredRule]
                                      }, null, 8, ["modelValue", "rules"])
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_v_row, null, {
                                default: _withCtx(() => [
                                  _createVNode(_component_v_col, { cols: "6" }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_v_select, {
                                        label: "Besitzer",
                                        modelValue: _ctx.apartment.owners,
                                        "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.apartment.owners) = $event)),
                                        items: _ctx.persons,
                                        "item-title": "fullname",
                                        "item-value": "id",
                                        required: "",
                                        multiple: "",
                                        chips: "",
                                        rules: [_ctx.requiredRule]
                                      }, null, 8, ["modelValue", "items", "rules"])
                                    ]),
                                    _: 1
                                  }),
                                  _createVNode(_component_v_col, { cols: "6" }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_v_select, {
                                        label: "Vermieter",
                                        modelValue: _ctx.apartment.landlords,
                                        "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.apartment.landlords) = $event)),
                                        items: _ctx.persons,
                                        "item-title": "fullname",
                                        "item-value": "id",
                                        multiple: "",
                                        chips: ""
                                      }, null, 8, ["modelValue", "items"])
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_v_row, null, {
                                default: _withCtx(() => [
                                  _createVNode(_component_v_col, { cols: "6" }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_v_select, {
                                        label: "Mieter",
                                        modelValue: _ctx.apartment.tenants,
                                        "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.apartment.tenants) = $event)),
                                        items: _ctx.persons,
                                        "item-title": "fullname",
                                        "item-value": "id",
                                        multiple: "",
                                        chips: ""
                                      }, null, 8, ["modelValue", "items"])
                                    ]),
                                    _: 1
                                  }),
                                  _createVNode(_component_v_col, { cols: "6" }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_v_select, {
                                        label: "Untermieter",
                                        modelValue: _ctx.apartment.subtenants,
                                        "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.apartment.subtenants) = $event)),
                                        items: _ctx.persons,
                                        "item-title": "fullname",
                                        "item-value": "id",
                                        multiple: "",
                                        chips: ""
                                      }, null, 8, ["modelValue", "items"])
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }, 512)
                ]),
                _: 1
              }),
              _createVNode(_component_v_window_item, { value: 2 }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_col, {
                    cols: "12",
                    class: "pa-4"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ckeditor, {
                        editor: _ctx.EditorClass,
                        config: _ctx.EditorClass.defaultConfig,
                        modelValue: _ctx.apartment.text,
                        "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.apartment.text) = $event))
                      }, null, 8, ["editor", "config", "modelValue"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["modelValue"]),
          _createVNode(_component_v_card_actions, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_spacer),
              _createVNode(_component_v_btn, {
                color: "blue darken-1",
                text: "",
                onClick: _ctx.closeDialog
              }, {
                default: _withCtx(() => [
                  _createTextVNode(" Abbrechen ")
                ]),
                _: 1
              }, 8, ["onClick"]),
              _createVNode(_component_v_btn, {
                color: "blue darken-1",
                text: "",
                onClick: _ctx.updateApartment,
                disabled: !_ctx.isFormValid(_ctx.apartment)
              }, {
                default: _withCtx(() => [
                  _createTextVNode(" Speichern ")
                ]),
                _: 1
              }, 8, ["onClick", "disabled"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }))
    : _createCommentVNode("", true)
}