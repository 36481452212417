<template>
	<!-- Wohnungsdetails anzeigen -->
	<v-card v-if="apartmentToView">
		<v-card-title>Wohnungsdetails</v-card-title>
		<!-- Tabs für verschiedene Informationen -->
		<v-tabs v-model="currentTab" background-color="primary" dark>
			<v-tab :value="1">Details</v-tab>
			<v-tab :value="2">Bewohner</v-tab>
			<v-tab :value="3">Beschreibung</v-tab>
		</v-tabs>
		<v-window v-model="currentTab">
			<!-- Tab 1: Details -->
			<v-window-item :value="1">
				<v-form ref="form" class="pa-4">
					<v-row>
						<!-- Linke Seite: Details -->
						<v-col cols="12">
							<v-row>
								<v-col cols="6">
									<v-text-field label="Name" v-model="apartment.name" disabled></v-text-field>
								</v-col>
								<v-col cols="6">
									<v-text-field label="Ort" v-model="apartment.location" disabled></v-text-field>
								</v-col>
								<v-col cols="6">
									<v-text-field label="Straße" v-model="apartment.street" disabled></v-text-field>
								</v-col>
								<v-col cols="6">
									<v-text-field label="Hausnummer" v-model="apartment.housenumber" disabled></v-text-field>
								</v-col>
								<v-col cols="6">
									<v-text-field label="Anzahl der Einheiten" v-model="apartment.units" disabled></v-text-field>
								</v-col>
							</v-row>
						</v-col>
					</v-row>
				</v-form>
			</v-window-item>
			<!-- Tab 1: Details -->
			<v-window-item :value="2">
				<v-form ref="form" class="pa-4">
					<v-row>

						<!-- Rechte Seite: Tabellen für Besitzer, Vermieter, Mieter, Untermieter -->
						<v-col cols="12">
							<v-row>
								<v-col cols="12">
									<h4>Besitzer</h4>
									<v-data-table :headers="personHeaders" :items="getPersonsByIds(apartment.owners)" hide-default-footer>
										<template v-slot:[`item.actions`]="{ item }">
											<v-btn icon @click="openPersonView(item)" title="Anzeigen">
												<v-icon>mdi-eye</v-icon>
											</v-btn>
										</template>
									</v-data-table>
								</v-col>
								<v-col cols="12" class="mt-4">
									<h4>Vermieter</h4>
									<v-data-table :headers="personHeaders" :items="getPersonsByIds(apartment.landlords)" hide-default-footer>
										<template v-slot:[`item.actions`]="{ item }">
											<v-btn icon @click="openPersonView(item)" title="Anzeigen">
												<v-icon>mdi-eye</v-icon>
											</v-btn>
										</template>
									</v-data-table>
								</v-col>
								<v-col cols="12" class="mt-4">
									<h4>Mieter</h4>
									<v-data-table :headers="personHeaders" :items="getPersonsByIds(apartment.tenants)" hide-default-footer>
										<template v-slot:[`item.actions`]="{ item }">
											<v-btn icon @click="openPersonView(item)" title="Anzeigen">
												<v-icon>mdi-eye</v-icon>
											</v-btn>
										</template>
									</v-data-table>
								</v-col>
								<v-col cols="12" class="mt-4">
									<h4>Untermieter</h4>
									<v-data-table :headers="personHeaders" :items="getPersonsByIds(apartment.subtenants)" hide-default-footer>
										<template v-slot:[`item.actions`]="{ item }">
											<v-btn icon @click="openPersonView(item)" title="Anzeigen">
												<v-icon>mdi-eye</v-icon>
											</v-btn>
										</template>
									</v-data-table>
								</v-col>
							</v-row>
						</v-col>
					</v-row>
				</v-form>
			</v-window-item>

			<!-- Tab 2: Beschreibung -->
			<v-window-item :value="3">
				<v-row>
					<v-col cols="12" class="pa-4">
						<h4>Beschreibung</h4>
						<div v-html="apartment.text" class="pa-4"></div>
					</v-col>
				</v-row>
			</v-window-item>
		</v-window>

		<!-- Aktionsbuttons -->
		<v-card-actions>
			<v-spacer></v-spacer>
			<v-btn color="blue darken-1" text @click="closeDialog">Schließen</v-btn>
		</v-card-actions>
	</v-card>

	<!-- PersonView Component -->
	<component :is="viewPersonComponent" v-model="viewPersonDialog" :personToView="selectedPerson" @close="viewPersonClose"/>
</template>

<script lang="ts">
import { defineComponent, ref, computed, onMounted, watch, PropType } from "vue";
import { ApartmentWithRelations } from "@/types/Apartment";
import { PersonFile } from "@/types/Person";
import api from "@/api";
import AuthorityViewPersonFile from "@/components/PersonFile/Authority/View.vue";

export default defineComponent({
	name: "ApartmentView",
	props: {
		modelValue: {
			type: Boolean,
			required: true,
		},
		apartmentToView: {
			type: Object as PropType<ApartmentWithRelations>,
			required: true,
		},
		viewApartmentDialog: {
			type: Boolean,
			required: true,
		},
	},
	emits: ["update:modelValue", 'close'],
	setup(props, { emit }) {
		const dialog = computed({
			get: () => props.modelValue,
			set: (value) => {
				emit("update:modelValue", value);
			},
		});

		const apartment = ref<ApartmentWithRelations>({
			...props.apartmentToView,
			owners: props.apartmentToView?.owners || [],
			tenants: props.apartmentToView?.tenants || [],
			landlords: props.apartmentToView?.landlords || [],
			subtenants: props.apartmentToView?.subtenants || [],
		});

		const persons = ref<PersonFile[]>([]);
		const personHeaders = [
			{ title: "Name", value: "name" },
			{ title: "Telefonnummer", value: "phonenumber" },
			{ title: "E-Mail", value: "mail" },
			{ title: "Aktionen", value: "actions", sortable: false },
		];

		const selectedPerson = ref<PersonFile | null>(null);
		const viewPersonDialog = ref(false);

		const viewPersonComponent = computed(() => {
			return AuthorityViewPersonFile;
		});

		const fetchPersons = async () => {
			try {
				const response = await api.get("personfile/?action=getPersons");
				persons.value = response.data.map((person: PersonFile) => ({
					...person,
					name: `${person.firstname} ${person.lastname}`,
				}));
			} catch (error) {
				console.error("Fehler beim Laden der Personen:", error);
			}
		};

		onMounted(fetchPersons);

		watch(
			() => props.apartmentToView,
			(newApartment) => {
				if (newApartment) {
					apartment.value = {
						...newApartment,
						owners: newApartment.owners || [],
						tenants: newApartment.tenants || [],
						landlords: newApartment.landlords || [],
						subtenants: newApartment.subtenants || [],
					};
				}
			},
			{ immediate: true }
		);

		const getPersonsByIds = (ids: number[]) => {
			return persons.value.filter((person) => ids.includes(person.id));
		};

		const openPersonView = (person: PersonFile) => {
			selectedPerson.value = person;
			viewPersonDialog.value = true;
		};

		const closeDialog = () => {
			dialog.value = false;
			emit("close");
		};

		const viewPersonClose = () => {
			selectedPerson.value = null;
			viewPersonDialog.value = false;
		};

		const currentTab = ref(1);

		return {
			dialog,
			apartment,
			persons,
			personHeaders,
			viewPersonComponent,
			viewPersonDialog,
			selectedPerson,
			getPersonsByIds,
			openPersonView,
			closeDialog,
			currentTab,
			viewPersonClose
		};
	},
});
</script>
