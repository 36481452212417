<template>
	<v-container>
		<v-btn @click="openAddPersonDialog" class="float-right" v-if="!addPersonDialog && !editPersonDialog && !selectedPerson">Neue Person</v-btn>

		<v-data-table
			:headers="personHeaders"
			:items="filteredPersons"
			class="elevation-1 mt-12"
			:search="search"
			:items-per-page="10"
			v-if="!addPersonDialog && !editPersonDialog && !selectedPerson"
		>
			<template v-slot:top>
				<v-toolbar flat>
					<v-toolbar-title>Personenverwaltung</v-toolbar-title>
					<v-spacer></v-spacer>
					<v-text-field
						v-model="search"
						label="Suche"
						class="mx-4 mt-3"
						variant="underlined"
					/>
				</v-toolbar>
			</template>
			<template v-slot:[`item.name`]="{ item }">
				<span size="small" class="me-2" @click="openViewPersonDialog(item)" style="cursor: pointer">
					{{ item.name }}
				</span>
			</template>

			<template v-slot:[`item.actions`]="{ item }">
				<v-icon size="small" class="me-2" @click="openEditPersonDialog(item)">mdi-pencil</v-icon>
				<v-icon size="small" class="me-2" @click="openDeletePersonDialog(item)">mdi-delete</v-icon>
			</template>
		</v-data-table>

		<!-- AddPersonFile Component -->
		<AuthorityAddPersonFile v-model="addPersonDialog" :addPersonDialog="addPersonDialog" @personAdded="onPersonAdded" />

		<!-- EditPersonFile Component -->
		<AuthorityEditPersonFile v-model="editPersonDialog" :editPersonDialog="editPersonDialog" :personToEdit="editedPerson" @personUpdated="onPersonUpdated" @close="closeEditFile"/>

		<!-- ViewPersonFile Component -->
		<AuthorityViewPersonFile v-model="viewPersonDialog" :personToView="selectedPerson" @close="closeViewFile"/>

		<!-- Delete Person Dialog -->
		<v-dialog v-model="deletePersonDialog" max-width="400" persistent>
			<v-card>
				<v-card-title class="headline">Person löschen</v-card-title>
				<v-card-text>
					Möchten Sie die Person "{{ selectedPerson?.firstname }} {{ selectedPerson?.lastname }}" wirklich löschen?
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="blue darken-1" text @click="deletePersonDialog = false">Abbrechen</v-btn>
					<v-btn color="blue darken-1" text @click="deletePerson(selectedPerson)">Löschen</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<!-- Snackbar -->
		<v-snackbar v-model="snackbar.value" :color="snackbar.color" :timeout="4000">
			{{ snackbar.message }}
		</v-snackbar>
	</v-container>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref, computed } from "vue";
import api from "@/api";
import { PersonFile, FirePerson, PolicePerson, MedicalPerson, JusticePerson, StateParkPerson, CasaPerson, Person } from "@/types/Person";
import { useStore } from "vuex";
// Authority-specific unified components
import AuthorityAddPersonFile from "@/components/PersonFile/Authority/Add.vue";
import AuthorityEditPersonFile from "@/components/PersonFile/Authority/Edit.vue";
import AuthorityViewPersonFile from "@/components/PersonFile/Authority/View.vue";

export default defineComponent({
	components: {
		// Register unified authority-specific components
		AuthorityAddPersonFile,
		AuthorityEditPersonFile,
		AuthorityViewPersonFile,
	},
	setup() {
		const store = useStore();
		const personHeaders = [
			{ title: "Name", key: "name", sortable: true },
			{ title: "Telefonnummer", key: "phonenumber", sortable: true },
			{ title: "Geburtsdatum", key: "birthday", sortable: true },
			{ title: "EMail", key: "mail", sortable: false },
			{ title: "Aktionen", key: "actions", sortable: false },
		];

		const persons = ref<PersonFile[]>([]);
		const addPersonDialog = ref(false);
		const editPersonDialog = ref(false);
		const viewPersonDialog = ref(false);
		const deletePersonDialog = ref(false);
		const editedPerson = ref<PersonFile | null>(null);
		const selectedPerson = ref<PersonFile | null>(null);
		const search = ref("");
		const snackbar = ref({
			value: false,
			color: "",
			message: "",
		});

		const fetchPersons = async () => {
			try {
				const response = await api.get("personfile/?action=getPersons");
				const authority = store.state.user.authority; // Get the current authority type

				persons.value = response.data.map((entry) => {
					// Create a base person object
					const basePerson: Person = {
						id: entry.id,
						name: `${entry.firstname} ${entry.lastname}`,
						fullname: entry.fullname || "",
						firstname: entry.firstname,
						lastname: entry.lastname,
						gender: entry.gender,
						title: entry.title,
						birthplace: entry.birthplace,
						birthday: entry.birthday,
						phonenumber: entry.phonenumber,
						address: entry.address,
						idcard: entry.idcard,
						bankaccount: entry.bankaccount,
						mail: entry.mail,
						entry: entry.entry,
						licenses: entry.licenses,
						wanted: entry.wanted === "1",
						text: entry.text,
						is_deleted: entry.is_deleted === "1",
					};

					// Add authority-specific fields based on the authority type
					if (authority === "fire") {
						return {
							...basePerson,
						} as FirePerson;
					} else if (authority === "police" || authority === 'test') {
						return {
							...basePerson,
							lastKnownLocation: entry.lastKnownLocation || "",
							gangAffiliation: entry.lastKnownLocation || "",
						} as PolicePerson;
					} else if (authority === "medic") {
						return {
							...basePerson,
							allergies: entry.allergies || "",
							emergencyContact: entry.emergencyContact || "",
							insured: entry.insured || "",
							bloodType: entry.bloodType || "",
							firstAid: entry.firstAid || "",
						} as MedicalPerson;
					} else if (authority === "justice") {
						return {
							...basePerson,
							lastKnownLocation: entry.lastKnownLocation || "",
							gangAffiliation: entry.gangAffiliation || "",
						} as JusticePerson;
					} else if (authority === "statepark") {
						return {
							...basePerson,
						} as StateParkPerson;
					} else if (authority === "casa") {
						return {
							...basePerson,
							job: entry.job || "",
							freelance: entry.freelance || "",
						} as CasaPerson;
					}

					return basePerson; // Fallback for unknown authority types
				});
			} catch (error) {
				snackbar.value.message = error.response?.data?.error || "Fehler beim Laden der Personen";
				snackbar.value.color = "error";
				snackbar.value.value = true;
			}
		};

		const filteredPersons = computed(() => {
			const searchTerm = search.value.trim().toLowerCase();
			return persons.value.filter((person) => {
				const searchableContent = `${person.name} ${person.phonenumber} ${person.mail}`.toLowerCase();
				return searchTerm ? searchableContent.includes(searchTerm) : true;
			});
		});

		const openAddPersonDialog = () => {
			addPersonDialog.value = true;
		};

		const onPersonAdded = async () => {
			await fetchPersons();
		};

		const openEditPersonDialog = (person: PersonFile) => {
			editedPerson.value = { ...person };
			editPersonDialog.value = true;
		};

		const onPersonUpdated = async () => {
			await fetchPersons();
		};

		const closeEditFile = async () => {
			editPersonDialog.value = false;
			editedPerson.value = null;
		}

		const closeViewFile = async () => {
			selectedPerson.value = null;
		}

		const openViewPersonDialog = (person: PersonFile) => {
			selectedPerson.value = { ...person };
			viewPersonDialog.value = true;
		};

		const openDeletePersonDialog = (person: PersonFile) => {
			selectedPerson.value = person;
			deletePersonDialog.value = true;
		};

		const deletePerson = async (person: PersonFile) => {
			try {
				await api.post("personfile/?action=deletePerson", {
					id: person.id,
				});
				await fetchPersons();
				deletePersonDialog.value = false;
				snackbar.value.message = "Person erfolgreich gelöscht";
				snackbar.value.color = "success";
				snackbar.value.value = true;
			} catch (error) {
				snackbar.value.message = error.response?.data?.error || "Fehler beim Löschen der Person";
				snackbar.value.color = "error";
				snackbar.value.value = true;
			}
		};

		onMounted(async () => {
			await fetchPersons();
		});

		return {
			personHeaders,
			persons,
			filteredPersons,
			addPersonDialog,
			editPersonDialog,
			viewPersonDialog,
			deletePersonDialog,
			editedPerson,
			selectedPerson,
			search,
			snackbar,
			openAddPersonDialog,
			openEditPersonDialog,
			openViewPersonDialog,
			openDeletePersonDialog,
			deletePerson,
			onPersonAdded,
			onPersonUpdated,
			closeEditFile,
			closeViewFile
		};
	},
});
</script>
