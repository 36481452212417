<template>
	<!-- Personenansicht -->
	<v-card v-if="personToView">
		<v-card-title>
			{{ person.firstname }} {{ person.lastname }}
		</v-card-title>
		<!-- Tabs für verschiedene Formularinformationen -->
		<v-tabs v-model="currentTab" background-color="primary" dark>
			<v-tab :value="1">Personeninfos</v-tab>
			<v-tab :value="2">Fahrzeuge & Wohnungen</v-tab>
		</v-tabs>
		<v-window v-model="currentTab">
			<!-- Tab 1: Personeninformationen -->
			<v-window-item :value="1">
				<v-form ref="form" class="pa-4">
					<v-row>
						<v-col cols="12">
							<v-row>
								<!-- Allgemeine Felder -->
								<v-col cols="6">
									<v-text-field
										label="Vorname"
										v-model="person.firstname"
										disabled
									></v-text-field>
								</v-col>
								<v-col cols="6">
									<v-text-field
										label="Nachname"
										v-model="person.lastname"
										disabled
									></v-text-field>
								</v-col>
								<v-col cols="6">
									<v-text-field
										label="Geburtsort"
										v-model="person.birthplace"
										disabled
									></v-text-field>
								</v-col>
								<v-col cols="6">
									<v-text-field
										label="Geburtsdatum"
										v-model="person.birthday"
										disabled
									></v-text-field>
								</v-col>
								<v-col cols="6">
									<v-text-field
										label="Telefonnummer"
										v-model="person.phonenumber"
										disabled
									></v-text-field>
								</v-col>
								<v-col cols="6">
									<v-text-field
										label="Adresse"
										v-model="person.address"
										disabled
									></v-text-field>
								</v-col>
								<v-col cols="6">
									<v-text-field
										label="Personalausweisnummer"
										v-model="person.idcard"
										disabled
									></v-text-field>
								</v-col>
								<v-col cols="6">
									<v-text-field
										label="Bankkonto"
										v-model="person.bankaccount"
										disabled
									></v-text-field>
								</v-col>
								<v-col cols="6">
									<v-text-field
										label="E-Mail"
										v-model="person.mail"
										disabled
									></v-text-field>
								</v-col>
								<v-col cols="6">
									<v-text-field
										label="Eintrittsdatum"
										v-model="person.entry"
										disabled
									></v-text-field>
								</v-col>
								<v-col cols="6">
									<v-text-field
										label="Lizenzen"
										v-model="person.licenses"
										disabled
									></v-text-field>
								</v-col>
								<v-col cols="12">
									<v-checkbox
										v-model="person.wanted"
										label="Gesucht"
										disabled
									></v-checkbox>
								</v-col>

								<!-- Authority-spezifische Felder -->
								<template v-if="authority === 'fire'">
								</template>

								<template v-if="authority === 'police' || authority === 'test'">
									<v-col cols="12">
										<v-text-field
											label="Letzter bekannter Ort"
											v-model="person.lastKnownLocation"
										></v-text-field>
									</v-col>
									<v-col cols="12">
										<v-text-field
											label="Gangangehörigkeit"
											v-model="person.gangAffiliation"
										></v-text-field>
									</v-col>
								</template>

								<template v-if="authority === 'medic'">
									<v-col cols="12">
										<v-text-field
											label="Allergien"
											v-model="person.allergies"
										></v-text-field>
									</v-col>
									<v-col cols="12">
										<v-text-field
											label="Notfallkontakt"
											v-model="person.emergencyContact"
										></v-text-field>
									</v-col>
									<v-col cols="12">
										<v-text-field
											label="Versichert?"
											v-model="person.insured"
										></v-text-field>
									</v-col>
									<v-col cols="12">
										<v-text-field
											label="Blutgruppe"
											v-model="person.bloodType"
										></v-text-field>
									</v-col>
									<v-col cols="12">
										<v-text-field
											label="Erste Hilfe Kurs?"
											v-model="person.firstAid"
										></v-text-field>
									</v-col>
								</template>

								<template v-if="authority === 'justice'">
									<v-col cols="12">
										<v-text-field
											label="Letzter bekannter Ort"
											v-model="person.lastKnownLocation"
										></v-text-field>
									</v-col>
									<v-col cols="12">
										<v-text-field
											label="Gangangehörigkeit"
											v-model="person.gangAffiliation"
										></v-text-field>
									</v-col>
								</template>

								<template v-if="authority === 'statepark'">
								</template>

								<template v-if="authority === 'casa'">
									<v-col cols="12">
										<v-text-field
											label="Beruf"
											v-model="person.job"
										></v-text-field>
									</v-col>
									<v-col cols="12">
										<v-text-field
											label="Freiberufliche Tätigkeit"
											v-model="person.freelance"
										></v-text-field>
									</v-col>
								</template>
							</v-row>
						</v-col>
					</v-row>
				</v-form>
			</v-window-item>

			<!-- Tab 2: Zusätzliche Informationen -->
			<v-window-item :value="2">
				<v-col cols="12" class="pa-4">
					<!-- Fahrzeuge -->
					<h3>Fahrzeuge</h3>
					<v-data-table
						:headers="vehicleHeaders"
						:items="vehicles"
						class="mt-4"
						:items-per-page="5"
					>
						<template v-slot:[`item.role`]="{ item }">
							<span>{{ item.role }}</span>
						</template>
						<template v-slot:[`item.actions`]="{ item }">
							<v-icon
								size="small"
								class="me-2"
								@click="openViewVehicleDialog(item)"
								style="cursor: pointer"
								>mdi-eye</v-icon
							>
						</template>
					</v-data-table>

					<!-- Wohnungen -->
					<h3 class="mt-4">Wohnungen</h3>
					<v-data-table
						:headers="apartmentHeaders"
						:items="apartments"
						class="mt-4"
						:items-per-page="5"
					>
						<template v-slot:[`item.role`]="{ item }">
							<span>{{ item.role }}</span>
						</template>
						<template v-slot:[`item.actions`]="{ item }">
							<v-icon
								size="small"
								class="me-2"
								@click="openViewApartmentDialog(item)"
								style="cursor: pointer"
								>mdi-eye</v-icon
							>
						</template>
					</v-data-table>

					<!-- Beschreibung -->
					<h3 class="mt-4">Beschreibung</h3>
					<div v-html="person.text" class="pa-4"></div>
				</v-col>
			</v-window-item>
		</v-window>

		<!-- Aktionsbuttons -->
		<v-card-actions>
			<v-spacer></v-spacer>
			<v-btn color="blue darken-1" text @click="closeDialog">
				Schließen
			</v-btn>
		</v-card-actions>

		<!-- VehicleView Component -->
		<component
			:is="viewVehicleComponent"
			v-model="viewVehicleDialog"
			:vehicleToView="selectedVehicle"
			@close="viewVehicleClose"
		/>
		<!-- ApartmentView Component -->
		<component
			:is="viewApartmentComponent"
			v-model="viewApartmentDialog"
			:apartmentToView="selectedApartment"
			@close="viewApartmentClose"
		/>
	</v-card>
</template>

<script lang="ts">
import {
	defineComponent,
	ref,
	computed,
	onMounted,
	watch,
	PropType,
} from "vue";
import { PersonFile } from "@/types/Person";
import { VehicleFile } from "@/types/Vehicle";
import { ApartmentWithRelations } from "@/types/Apartment";
import api from "@/api";
import { useStore } from "vuex";
// Import authority-specific components
import AuthorityViewVehicleFile from "@/components/VehicleFile/Authority/View.vue";
import AuthorityViewApartmentFile from "@/components/ApartmentFile/Authority/View.vue";

export default defineComponent({
	name: "ViewPersonFile",
	props: {
		modelValue: {
			type: Boolean,
			required: true,
		},
		personToView: {
			type: Object as PropType<PersonFile>,
			required: true,
		},
		viewPersonDialog: {
			type: Boolean,
			required: true,
		},
	},
	emits: ["update:modelValue", "close"],
	setup(props, { emit }) {
		const store = useStore();
		const authority = store.state.user.authority;

		const dialog = computed({
			get: () => props.modelValue,
			set: (value) => {
				emit("update:modelValue", value);
			},
		});

		const person = ref<PersonFile>({ ...props.personToView });
		const linkedReports = ref<any[]>([]);
		const vehicles = ref<VehicleFile[]>([]);
		const apartments = ref<ApartmentWithRelations[]>([]);
		const viewVehicleDialog = ref(false);
		const selectedVehicle = ref<VehicleFile | null>(null);
		const viewApartmentDialog = ref(false);
		const selectedApartment = ref<ApartmentWithRelations | null>(null);
		const currentTab = ref(1);

		const vehicleHeaders = [
			{ title: "Marke", value: "brand" },
			{ title: "Modell", value: "model" },
			{ title: "Kennzeichen", value: "numberplate" },
			{ title: "Farbe", value: "color" },
			{ title: "Registriert am", value: "registered" },
			{ title: "Rolle", value: "role" },
			{ title: "Aktionen", value: "actions", sortable: false },
		];

		const apartmentHeaders = [
			{ title: "Name", value: "name" },
			{ title: "Ort", value: "location" },
			{ title: "Straße", value: "street" },
			{ title: "Hausnummer", value: "housenumber" },
			{ title: "Rolle", value: "role" },
			{ title: "Aktionen", value: "actions", sortable: false },
		];

		const viewVehicleComponent = computed(() => {
			return AuthorityViewVehicleFile;
		});

		const viewApartmentComponent = computed(() => {
			return AuthorityViewApartmentFile;
		});

		const fetchVehicles = async () => {
			try {
				const response = await api.get(
					`vehiclefile/?action=getVehiclesByPerson&personId=${person.value.id}`
				);
				vehicles.value = response.data.map((vehicle) => ({
					...vehicle,
					role:
						vehicle.owner === person.value.id
							? "Besitzer"
							: "Fahrer",
					stolen: vehicle.stolen === "1",
					wanted: vehicle.wanted === "1",
				}));
				console.log("Loaded vehicles:", vehicles.value);
			} catch (error) {
				console.error("Fehler beim Laden der Fahrzeuge:", error);
			}
		};

		const fetchApartments = async () => {
			try {
				const response = await api.get(
					`apartmentfile/?action=getApartmentsByPerson&personId=${person.value.id}`
				);
				apartments.value = response.data.map((apartment) => ({
					...apartment,
					role: apartment.role,
				}));
				console.log("Mapped apartments:", apartments.value);
			} catch (error) {
				console.error("Fehler beim Laden der Wohnungen:", error);
			}
		};

		const openViewVehicleDialog = (vehicle: VehicleFile) => {
			selectedVehicle.value = vehicle;
			viewVehicleDialog.value = true;
		};

		const viewVehicleClose = (vehicle: VehicleFile) => {
			selectedVehicle.value = null;
			viewVehicleDialog.value = false;
		};

		const openViewApartmentDialog = (apartment: ApartmentWithRelations) => {
			selectedApartment.value = apartment;
			viewApartmentDialog.value = true;
		};

		const viewApartmentClose = (apartment: ApartmentWithRelations) => {
			selectedApartment.value = null;
			viewApartmentDialog.value = false;
		};

		const closeDialog = () => {
			dialog.value = false;
			emit("close");
		};

		watch(
			() => props.personToView,
			(newVal) => {
				person.value = { ...newVal };
				fetchVehicles();
				fetchApartments();
			},
			{ immediate: true }
		);

		onMounted(() => {
			fetchVehicles();
			fetchApartments();
		});

		return {
			dialog,
			person,
			vehicles,
			apartments,
			vehicleHeaders,
			apartmentHeaders,
			viewVehicleDialog,
			viewApartmentDialog,
			selectedVehicle,
			selectedApartment,
			viewVehicleComponent,
			viewApartmentComponent,
			openViewVehicleDialog,
			openViewApartmentDialog,
			closeDialog,
			authority,
			currentTab,
			viewVehicleClose,
			viewApartmentClose,
		};
	},
});
</script>
