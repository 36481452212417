import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_tab = _resolveComponent("v-tab")!
  const _component_v_tabs = _resolveComponent("v-tabs")!
  const _component_v_select = _resolveComponent("v-select")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_checkbox = _resolveComponent("v-checkbox")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_form = _resolveComponent("v-form")!
  const _component_v_window_item = _resolveComponent("v-window-item")!
  const _component_ckeditor = _resolveComponent("ckeditor")!
  const _component_v_window = _resolveComponent("v-window")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_card = _resolveComponent("v-card")!

  return (_ctx.vehicleToEdit)
    ? (_openBlock(), _createBlock(_component_v_card, { key: 0 }, {
        default: _withCtx(() => [
          _createVNode(_component_v_card_title, null, {
            default: _withCtx(() => [
              _createTextVNode(" Fahrzeug bearbeiten ")
            ]),
            _: 1
          }),
          _createVNode(_component_v_tabs, {
            modelValue: _ctx.currentTab,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.currentTab) = $event)),
            "background-color": "primary",
            dark: ""
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_tab, { value: 1 }, {
                default: _withCtx(() => [
                  _createTextVNode("Fahrzeuginfos")
                ]),
                _: 1
              }),
              _createVNode(_component_v_tab, { value: 2 }, {
                default: _withCtx(() => [
                  _createTextVNode("Zusätzliche Informationen")
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["modelValue"]),
          _createVNode(_component_v_window, {
            modelValue: _ctx.currentTab,
            "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.currentTab) = $event))
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_window_item, { value: 1 }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_form, {
                    ref: "form",
                    class: "pa-4"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_row, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_col, { cols: "12" }, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_select, {
                                modelValue: _ctx.vehicle.owners,
                                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.vehicle.owners) = $event)),
                                items: _ctx.persons,
                                "item-title": "fullname",
                                "item-value": "id",
                                label: "Besitzer",
                                required: "",
                                rules: [_ctx.requiredRule],
                                multiple: "",
                                chips: ""
                              }, null, 8, ["modelValue", "items", "rules"])
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_v_col, { cols: "12" }, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_select, {
                                modelValue: _ctx.vehicle.drivers,
                                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.vehicle.drivers) = $event)),
                                items: _ctx.persons,
                                "item-title": "fullname",
                                "item-value": "id",
                                label: "Fahrer",
                                required: "",
                                rules: [_ctx.requiredRule],
                                multiple: "",
                                chips: ""
                              }, null, 8, ["modelValue", "items", "rules"])
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_v_col, { cols: "6" }, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_text_field, {
                                label: "Marke",
                                modelValue: _ctx.vehicle.brand,
                                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.vehicle.brand) = $event)),
                                required: "",
                                rules: [_ctx.requiredRule]
                              }, null, 8, ["modelValue", "rules"])
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_v_col, { cols: "6" }, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_text_field, {
                                label: "Modell",
                                modelValue: _ctx.vehicle.model,
                                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.vehicle.model) = $event)),
                                required: "",
                                rules: [_ctx.requiredRule]
                              }, null, 8, ["modelValue", "rules"])
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_v_col, { cols: "6" }, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_text_field, {
                                label: "Kennzeichen",
                                modelValue: _ctx.vehicle.numberplate,
                                "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.vehicle.numberplate) = $event)),
                                required: "",
                                rules: [_ctx.requiredRule]
                              }, null, 8, ["modelValue", "rules"])
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_v_col, { cols: "6" }, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_text_field, {
                                label: "Farbe",
                                modelValue: _ctx.vehicle.color,
                                "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.vehicle.color) = $event))
                              }, null, 8, ["modelValue"])
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_v_col, { cols: "6" }, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_checkbox, {
                                modelValue: _ctx.vehicle.stolen,
                                "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.vehicle.stolen) = $event)),
                                label: "Gestohlen"
                              }, null, 8, ["modelValue"])
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_v_col, { cols: "6" }, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_checkbox, {
                                modelValue: _ctx.vehicle.wanted,
                                "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.vehicle.wanted) = $event)),
                                label: "Gesucht"
                              }, null, 8, ["modelValue"])
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_v_col, { cols: "12" }, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_text_field, {
                                label: "Registrierungsdatum",
                                modelValue: _ctx.vehicle.registered,
                                "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.vehicle.registered) = $event)),
                                type: "date"
                              }, null, 8, ["modelValue"])
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }, 512)
                ]),
                _: 1
              }),
              _createVNode(_component_v_window_item, { value: 2 }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_col, {
                    cols: "12",
                    class: "pa-4"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ckeditor, {
                        editor: _ctx.EditorClass,
                        config: _ctx.EditorClass.defaultConfig,
                        modelValue: _ctx.vehicle.text,
                        "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.vehicle.text) = $event))
                      }, null, 8, ["editor", "config", "modelValue"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["modelValue"]),
          _createVNode(_component_v_card_actions, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_spacer),
              _createVNode(_component_v_btn, {
                color: "blue darken-1",
                text: "",
                onClick: _ctx.closeDialog
              }, {
                default: _withCtx(() => [
                  _createTextVNode(" Abbrechen ")
                ]),
                _: 1
              }, 8, ["onClick"]),
              _createVNode(_component_v_btn, {
                color: "blue darken-1",
                text: "",
                onClick: _ctx.updateVehicle,
                disabled: !_ctx.isFormValid(_ctx.vehicle)
              }, {
                default: _withCtx(() => [
                  _createTextVNode(" Speichern ")
                ]),
                _: 1
              }, 8, ["onClick", "disabled"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }))
    : _createCommentVNode("", true)
}