import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_toolbar_title = _resolveComponent("v-toolbar-title")!
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_toolbar = _resolveComponent("v-toolbar")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_data_table = _resolveComponent("v-data-table")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_textarea = _resolveComponent("v-textarea")!
  const _component_v_form = _resolveComponent("v-form")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_dialog = _resolveComponent("v-dialog")!
  const _component_v_snackbar = _resolveComponent("v-snackbar")!
  const _component_v_container = _resolveComponent("v-container")!

  return (_openBlock(), _createBlock(_component_v_container, null, {
    default: _withCtx(() => [
      _createVNode(_component_v_data_table, {
        headers: _ctx.additionalHeaders,
        items: _ctx.additionals,
        class: "elevation-1"
      }, {
        top: _withCtx(() => [
          _createVNode(_component_v_toolbar, { flat: "" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_toolbar_title, null, {
                default: _withCtx(() => [
                  _createTextVNode("Zusätze verwalten")
                ]),
                _: 1
              }),
              _createVNode(_component_v_divider, {
                class: "mx-4",
                inset: "",
                vertical: ""
              }),
              _createVNode(_component_v_spacer),
              _createVNode(_component_v_btn, { onClick: _ctx.openNewAdditionalDialog }, {
                default: _withCtx(() => [
                  _createTextVNode("Neuer Zusatz")
                ]),
                _: 1
              }, 8, ["onClick"])
            ]),
            _: 1
          })
        ]),
        [`item.actions`]: _withCtx(({ item }) => [
          _createVNode(_component_v_icon, {
            size: "small",
            class: "me-2",
            onClick: ($event: any) => (_ctx.openEditAdditionalDialog(item))
          }, {
            default: _withCtx(() => [
              _createTextVNode("mdi-pencil")
            ]),
            _: 2
          }, 1032, ["onClick"]),
          _createVNode(_component_v_icon, {
            size: "small",
            class: "me-2",
            onClick: ($event: any) => (_ctx.openDeleteAdditionalDialog(item))
          }, {
            default: _withCtx(() => [
              _createTextVNode("mdi-delete")
            ]),
            _: 2
          }, 1032, ["onClick"])
        ]),
        _: 2
      }, 1032, ["headers", "items"]),
      _createVNode(_component_v_dialog, {
        modelValue: _ctx.newAdditionalDialog,
        "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.newAdditionalDialog) = $event)),
        "max-width": "60%"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_card, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_card_title, null, {
                default: _withCtx(() => [
                  _createTextVNode("Neue Zusatzleistung")
                ]),
                _: 1
              }),
              _createVNode(_component_v_card_text, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_form, { ref: "newAdditionalForm" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_text_field, {
                        label: "Name",
                        modelValue: _ctx.newAdditional.name,
                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.newAdditional.name) = $event)),
                        required: "",
                        rules: [_ctx.requiredRule]
                      }, null, 8, ["modelValue", "rules"]),
                      _createVNode(_component_v_textarea, {
                        label: "Beschreibung",
                        modelValue: _ctx.newAdditional.description,
                        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.newAdditional.description) = $event))
                      }, null, 8, ["modelValue"]),
                      _createVNode(_component_v_text_field, {
                        label: "Preis",
                        modelValue: _ctx.newAdditional.price,
                        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.newAdditional.price) = $event)),
                        required: "",
                        type: "number",
                        rules: [_ctx.requiredRule]
                      }, null, 8, ["modelValue", "rules"]),
                      _createVNode(_component_v_text_field, {
                        label: "Einheiten",
                        modelValue: _ctx.newAdditional.units,
                        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.newAdditional.units) = $event)),
                        type: "number"
                      }, null, 8, ["modelValue"])
                    ]),
                    _: 1
                  }, 512)
                ]),
                _: 1
              }),
              _createVNode(_component_v_card_actions, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_spacer),
                  _createVNode(_component_v_btn, {
                    color: "blue darken-1",
                    text: "",
                    onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.newAdditionalDialog = false))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("Abbrechen")
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_btn, {
                    color: "blue darken-1",
                    text: "",
                    onClick: _ctx.addNewAdditional,
                    disabled: !_ctx.isFormValid(_ctx.newAdditional)
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("Hinzufügen")
                    ]),
                    _: 1
                  }, 8, ["onClick", "disabled"])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["modelValue"]),
      _createVNode(_component_v_dialog, {
        modelValue: _ctx.editAdditionalDialog,
        "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.editAdditionalDialog) = $event)),
        "max-width": "60%"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_card, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_card_title, null, {
                default: _withCtx(() => [
                  _createTextVNode("Zusatzleistung bearbeiten")
                ]),
                _: 1
              }),
              _createVNode(_component_v_card_text, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_form, { ref: "editAdditionalForm" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_text_field, {
                        label: "Name",
                        modelValue: _ctx.editedAdditional.name,
                        "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.editedAdditional.name) = $event)),
                        required: "",
                        rules: [_ctx.requiredRule]
                      }, null, 8, ["modelValue", "rules"]),
                      _createVNode(_component_v_textarea, {
                        label: "Beschreibung",
                        modelValue: _ctx.editedAdditional.description,
                        "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.editedAdditional.description) = $event))
                      }, null, 8, ["modelValue"]),
                      _createVNode(_component_v_text_field, {
                        label: "Preis",
                        modelValue: _ctx.editedAdditional.price,
                        "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.editedAdditional.price) = $event)),
                        required: "",
                        type: "number",
                        rules: [_ctx.requiredRule]
                      }, null, 8, ["modelValue", "rules"]),
                      _createVNode(_component_v_text_field, {
                        label: "Einheiten",
                        modelValue: _ctx.editedAdditional.units,
                        "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.editedAdditional.units) = $event)),
                        type: "number"
                      }, null, 8, ["modelValue"])
                    ]),
                    _: 1
                  }, 512)
                ]),
                _: 1
              }),
              _createVNode(_component_v_card_actions, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_spacer),
                  _createVNode(_component_v_btn, {
                    color: "blue darken-1",
                    text: "",
                    onClick: _cache[10] || (_cache[10] = ($event: any) => (_ctx.editAdditionalDialog = false))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("Abbrechen")
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_btn, {
                    color: "blue darken-1",
                    text: "",
                    onClick: _ctx.updateAdditional
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("Speichern")
                    ]),
                    _: 1
                  }, 8, ["onClick"])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["modelValue"]),
      _createVNode(_component_v_dialog, {
        modelValue: _ctx.deleteAdditionalDialog,
        "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.deleteAdditionalDialog) = $event)),
        "max-width": "400",
        persistent: ""
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_card, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_card_title, { class: "headline" }, {
                default: _withCtx(() => [
                  _createTextVNode("Zusatzleistung löschen")
                ]),
                _: 1
              }),
              _createVNode(_component_v_card_text, null, {
                default: _withCtx(() => [
                  _createTextVNode(" Möchten Sie die Zusatzleistung \"" + _toDisplayString(_ctx.selectedAdditional?.name) + "\" wirklich löschen? ", 1)
                ]),
                _: 1
              }),
              _createVNode(_component_v_card_actions, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_spacer),
                  _createVNode(_component_v_btn, {
                    color: "blue darken-1",
                    text: "",
                    onClick: _cache[12] || (_cache[12] = ($event: any) => (_ctx.deleteAdditionalDialog = false))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("Abbrechen")
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_btn, {
                    color: "blue darken-1",
                    text: "",
                    onClick: _cache[13] || (_cache[13] = ($event: any) => (_ctx.deleteAdditional(_ctx.selectedAdditional)))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("Löschen")
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["modelValue"]),
      _createVNode(_component_v_snackbar, {
        modelValue: _ctx.snackbar.value,
        "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.snackbar.value) = $event)),
        color: _ctx.snackbar.color,
        timeout: 4000
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.snackbar.message), 1)
        ]),
        _: 1
      }, 8, ["modelValue", "color"])
    ]),
    _: 1
  }))
}