<template>
	<!-- Fahrzeug bearbeiten Formular -->
	<v-card v-if="vehicleToEdit">
		<v-card-title>
			Fahrzeug bearbeiten
		</v-card-title>
		<!-- Tabs für verschiedene Formularinformationen -->
		<v-tabs v-model="currentTab" background-color="primary" dark>
			<v-tab :value="1">Fahrzeuginfos</v-tab>
			<v-tab :value="2">Zusätzliche Informationen</v-tab>
		</v-tabs>
		<v-window v-model="currentTab">
			<!-- Tab 1: Fahrzeuginformationen -->
			<v-window-item :value="1">
				<v-form ref="form" class="pa-4">
					<v-row>
						<v-col cols="12">
							<!-- Besitzer Auswahl (Mehrfachauswahl) -->
							<v-select
								v-model="vehicle.owners"
								:items="persons"
								item-title="fullname"
								item-value="id"
								label="Besitzer"
								required
								:rules="[requiredRule]"
								multiple
								chips
							></v-select>
						</v-col>
						<v-col cols="12">
							<!-- Fahrer Auswahl (Mehrfachauswahl) -->
							<v-select
								v-model="vehicle.drivers"
								:items="persons"
								item-title="fullname"
								item-value="id"
								label="Fahrer"
								required
								:rules="[requiredRule]"
								multiple
								chips
							></v-select>
						</v-col>
						<v-col cols="6">
							<v-text-field
								label="Marke"
								v-model="vehicle.brand"
								required
								:rules="[requiredRule]"
							></v-text-field>
						</v-col>
						<v-col cols="6">
							<v-text-field
								label="Modell"
								v-model="vehicle.model"
								required
								:rules="[requiredRule]"
							></v-text-field>
						</v-col>
						<v-col cols="6">
							<v-text-field
								label="Kennzeichen"
								v-model="vehicle.numberplate"
								required
								:rules="[requiredRule]"
							></v-text-field>
						</v-col>
						<v-col cols="6">
							<v-text-field
								label="Farbe"
								v-model="vehicle.color"
							></v-text-field>
						</v-col>
						<v-col cols="6">
							<v-checkbox
								v-model="vehicle.stolen"
								label="Gestohlen"
							></v-checkbox>
						</v-col>
						<v-col cols="6">
							<v-checkbox
								v-model="vehicle.wanted"
								label="Gesucht"
							></v-checkbox>
						</v-col>
						<v-col cols="12">
							<v-text-field
								label="Registrierungsdatum"
								v-model="vehicle.registered"
								type="date"
							></v-text-field>
						</v-col>
					</v-row>
				</v-form>
			</v-window-item>

			<!-- Tab 2: Zusätzliche Informationen -->
			<v-window-item :value="2">
				<v-col cols="12" class="pa-4">
					<!-- Beschreibung (Rich-Text-Editor) -->
					<ckeditor
						:editor="EditorClass"
						:config="EditorClass.defaultConfig"
						v-model="vehicle.text"
					></ckeditor>
				</v-col>
			</v-window-item>
		</v-window>

		<!-- Aktionsbuttons -->
		<v-card-actions>
			<v-spacer></v-spacer>
			<v-btn color="blue darken-1" text @click="closeDialog">
				Abbrechen
			</v-btn>
			<v-btn
				color="blue darken-1"
				text
				@click="updateVehicle"
				:disabled="!isFormValid(vehicle)"
			>
				Speichern
			</v-btn>
		</v-card-actions>
	</v-card>
</template>

<script lang="ts">
import {
	defineComponent,
	ref,
	computed,
	onMounted,
	watch,
	PropType,
} from "vue";
import { VehicleFile } from "@/types/Vehicle";
import { PersonFile } from "@/types/Person";
import EditorClass from "@/ckeditor/ckeditor.js";
import api from "@/api";

export default defineComponent({
	name: "VehicleEdit",
	props: {
		modelValue: {
			type: Boolean,
			required: true,
		},
		vehicleToEdit: {
			type: Object as PropType<VehicleFile>,
			required: true,
		},
		editVehicleDialog: {
			type: Boolean,
			required: true,
		},
	},
	emits: ["update:modelValue", "vehicleUpdated", 'close'],
	setup(props, { emit }) {
		const dialog = computed({
			get: () => props.modelValue,
			set: (value) => {
				emit("update:modelValue", value);
			},
		});

		const vehicle = ref<VehicleFile>({ ...props.vehicleToEdit });
		const persons = ref<PersonFile[]>([]);
		const currentTab = ref(1);

		const fetchPersons = async () => {
			try {
				const response = await api.get(
					"vehiclefile/?action=getPersons"
				);
				persons.value = response.data.map((person: PersonFile) => ({
					...person,
					fullname: `${person.firstname} ${person.lastname}`,
				}));
			} catch (error) {
				// Fehlerbehandlung
			}
		};

		watch(
			() => props.vehicleToEdit,
			(newVal) => {
				vehicle.value = { ...newVal };
			},
			{ immediate: true }
		);

		const requiredRule = (value: string) =>
			!!value || "Dieses Feld ist erforderlich.";

		const updateVehicle = async () => {
			try {
				// Include multiple owners and drivers in the request payload
				const payload = {
					...vehicle.value,
					owners: vehicle.value.owners,
					drivers: vehicle.value.drivers,
				};
				await api.post("vehiclefile/?action=editVehicle", payload);
				emit("vehicleUpdated");
				closeDialog();
			} catch (error) {
				// Fehlerbehandlung
			}
		};

		const isFormValid = (form: VehicleFile) => {
			return (
				form.brand !== "" &&
				form.model !== "" &&
				form.numberplate !== ""
			);
		};

		const closeDialog = () => {
			dialog.value = false;
			emit("close");
		};

		onMounted(fetchPersons);

		return {
			dialog,
			vehicle,
			persons,
			currentTab,
			requiredRule,
			updateVehicle,
			isFormValid,
			closeDialog,
			EditorClass,
		};
	},
});
</script>
